var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react";
import { useMemo, useState } from "react";
export var useDialog = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.initialOpen, initialOpen = _c === void 0 ? false : _c, setControlledOpen = _b.onOpenChange, controlledOpen = _b.open;
    var _d = useState(initialOpen), uncontrolledOpen = _d[0], setUncontrolledOpen = _d[1];
    var _e = useState(), labelId = _e[0], setLabelId = _e[1];
    var _f = useState(), descriptionId = _f[0], setDescriptionId = _f[1];
    var open = controlledOpen !== null && controlledOpen !== void 0 ? controlledOpen : uncontrolledOpen;
    var setOpen = setControlledOpen !== null && setControlledOpen !== void 0 ? setControlledOpen : setUncontrolledOpen;
    var data = useFloating({
        onOpenChange: setOpen,
        open: open,
    });
    var context = data.context;
    var click = useClick(context);
    var dismiss = useDismiss(context);
    var role = useRole(context);
    var interactions = useInteractions([click, dismiss, role]);
    return useMemo(function () { return (__assign(__assign(__assign({ open: open, setOpen: setOpen }, interactions), data), { descriptionId: descriptionId, labelId: labelId, setDescriptionId: setDescriptionId, setLabelId: setLabelId })); }, [open, setOpen, interactions, data, labelId, descriptionId]);
};
