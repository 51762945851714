import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import classnames from "classnames";
import AppError from "./AppError";
import AppSuccess from "./AppSuccess";
import { COMPLETED_ORDER, FAILED_COMPLETE_ORDER, SOCIAL_SIGNIN_ERROR } from "./../../constants/AuthorizedStatusTypes";

import "../../css/deprecated/error-message-handler.css";

export default class ErrorMessageHandler extends Component {
  constructor() {
    super();

    this.hasDisappear = false;
    this.disappearingMessage;
    this.getMessage = this.getMessage.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.getIsMessage = this.getIsMessage.bind(this);
    this.renderTranslatorSettings = this.renderTranslatorSettings.bind(this);
    this.renderTranslatorDesktop = this.renderTranslatorDesktop.bind(this);
    this.renderForms = this.renderForms.bind(this);
  }

  renderTranslatorSettings() {
    return null;
  }

  renderTranslatorDesktop() {
    let isSuccess = COMPLETED_ORDER === this.props.desktop.message;
    let isOrange = FAILED_COMPLETE_ORDER === this.props.desktop.message;
    let isFailedAcceptOrder = "FAILED_ACCEPT_ORDER" === this.props.desktop.message;

    if (isFailedAcceptOrder) {
      return (
        <AppSuccess
          isOrange={true}
          onClose={this.props.onClose}
          message={I18n._translate("TRANSLATOR_APP.DESKTOP_PAGE.FAILED_ACCEPT_ORDER")}
          display={true}
        />
      );
    } else if (isOrange) {
      return (
        <AppSuccess
          isOrange={true}
          onClose={this.props.onClose}
          message={I18n._translate("TRANSLATOR_APP.DESKTOP_PAGE.FAILED_ORDER_COMPLETE")}
          display={true}
        />
      );
    } else if (isSuccess) {
      return (
        <AppSuccess
          message={I18n._translate("TRANSLATOR_APP.DESKTOP_PAGE.SUCCESS_ORDER_COMPLETE")}
          display={isSuccess}
        />
      );
    }
    return null;
  }

  renderForms() {
    if (this.props.socialSignin.status === SOCIAL_SIGNIN_ERROR) {
      return <AppError message={this.props.socialSignin.error} display={true} />;
    }
    return null;
  }

  renderError() {
    if (this.props.errors.feedbackSuccess) {
      return <AppSuccess message={I18n._translate("FAQ_TRANSLATOR.SUCCESS_MESSAGE")} display={true} />;
    } else if (this.props.errors.pollingFail) {
      return (
        <AppSuccess
          isOrange={true}
          onClose={this.props.onClose}
          message={I18n._translate("ERRORS.FAILED_POLLING")}
          display={true}
        />
      );
    }
    return null;
  }

  getMessage() {
    let isSettingsMessage = this.props.settings ? this.renderTranslatorSettings() : null;
    let isDesktopMessage = this.props.desktop ? this.renderTranslatorDesktop() : null;
    let isFormsMessage = this.props.socialSignin ? this.renderForms() : null;
    let isErrorMessage = this.props.errors ? this.renderError() : null;
    return [isSettingsMessage, isDesktopMessage, isFormsMessage, isErrorMessage].find(item => item !== null);
  }

  renderMessage() {
    let isMessage = this.getIsMessage();
    let Message = this.getMessage();
    if (isMessage) {
      this.hasDisappear = false;
      this.disappearingMessage = Message;
      return Message;
    } else if (!isMessage && !this.hasDisappear) {
      setTimeout(() => {
        this.hasDisappear = true;
      }, 1000);
      return this.disappearingMessage;
    } else if (!isMessage && this.hasDisappear) {
      return null;
    }
  }

  getIsMessage() {
    let isSettingsMessage = this.props.settings ? this.renderTranslatorSettings() : null;
    let isDesktopMessage = this.props.desktop ? this.renderTranslatorDesktop() : null;
    let isFormsMessage = this.props.socialSignin ? this.renderForms() : null;
    let isErrorMessage = this.props.errors ? this.renderError() : null;
    return !!(isSettingsMessage || isDesktopMessage || isFormsMessage || isErrorMessage);
  }

  render() {
    let errorMessage = classnames("error-message-handler", {
      "error-message-handler--active": this.getIsMessage(),
    });

    return <div className={errorMessage}>{this.renderMessage()}</div>;
  }
}
