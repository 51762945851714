var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMergeRefs } from "@floating-ui/react";
import { cloneElement, forwardRef, isValidElement } from "react";
import { usePopoverContext } from "../models";
export var PopoverReference = forwardRef(function (_a, forwardedRef) {
    var children = _a.children, _b = _a.isCustomReference, isCustomReference = _b === void 0 ? false : _b, props = __rest(_a, ["children", "isCustomReference"]);
    var context = usePopoverContext();
    // eslint-disable-next-line
    var childrenRef = children.ref;
    var ref = useMergeRefs([context.refs.setReference, forwardedRef, childrenRef]);
    // `isCustomReference` allows the user to pass any element as the anchor
    if (isCustomReference && isValidElement(children)) {
        return cloneElement(children, context.getReferenceProps(__assign(__assign({ ref: ref }, props), children.props)));
    }
    return (_jsx("button", __assign({ ref: ref, type: "button" }, context.getReferenceProps(props), { children: children })));
});
