import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import "../../css/deprecated/header.css";
import NitroButton from "../refactored/GreenButton";
import { Link } from "react-router-dom";
import { getCouponFromUrl, getDiscountFromUrl } from "../../helpers/getCouponFromUrl";
import { HandySvg } from "handy-svg";
import logo from "../../images/svg/logo-unauth.svg";
import logoMobile from "../../images/svg/logo-mobile.svg";
import MobileSignBtn from "../MobileSignBtn/MobileSignBtn";
import HtmlTranslate from "../HtmlTranslate";
import iconSet from "../../images/selection.json";
import IcomoonReact from "icomoon-react";

export default class UnauthorizedHeader extends Component {
  state = {
    isHoverMoreInfo: false,
    isDividerHover: false,
  };

  onHoverMoreInfo = isHoverMoreInfo => {
    this.setState({ isHoverMoreInfo });
  };

  onHover = isDividerHover => {
    this.setState({ isDividerHover });
  };

  render() {
    const { notInfo, loginBtn, handlerAuth, newOrder, handlerSignUp } = this.props;
    const { isDividerHover, isHoverMoreInfo } = this.state;
    const aboutStyle = isHoverMoreInfo ? { borderBottom: "none", color: "#FFFFFF" } : {};
    const isSupportPage = window.location.pathname === "/new/support";
    return (
      <div className="header header--un-auth">
        <div className="header-block absolute-center">
          <div style={{ width: "33%" }}>
            {(!newOrder || isSupportPage) && (
              <div
                className="header-block divider"
                onMouseEnter={() => this.onHover(true)}
                onMouseLeave={() => this.onHover(false)}
              >
                <Link
                  to={
                    getCouponFromUrl()
                      ? "/new?coupon=" + getCouponFromUrl()
                      : getDiscountFromUrl()
                        ? "/new?discount=" + getDiscountFromUrl()
                        : "/new"
                  }
                  id="header-block newOrder"
                  className="header-block newOrder"
                  title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PAGE_HEADING")}
                  reloadDocument={true}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    color={isDividerHover ? "#FFFFFF" : "#BDBDBD"}
                    size={16}
                    icon={"new_order"}
                  />
                </Link>
                <Link
                  to={
                    getCouponFromUrl()
                      ? "/new?coupon=" + getCouponFromUrl()
                      : getDiscountFromUrl()
                        ? "/new?discount=" + getDiscountFromUrl()
                        : "/new"
                  }
                  className="header-block newOrderLabel"
                  reloadDocument={true}
                >
                  {I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.PAGE_HEADING")}
                </Link>
              </div>
            )}
          </div>
          <div className="logo-wrapper" style={{ width: "33%" }}>
            <a href={I18n.t("HEADER.NITRO_LINK")} className="logo-link" target="_blank">
              <HandySvg className="logo-mobile" width="120" height="25" src={logoMobile} />
              <HandySvg className="logo-unauth" width="174" height="25" src={logo} />
            </a>
            <p className="logo-text">{I18n.t("HEADER.NITRO_LOGO_TEXT")}</p>
          </div>
          {loginBtn && (
            <div
              style={{
                width: "33%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 16,
              }}
            >
              <div
                onClick={() => {
                  return (window.location.pathname = "/new/support");
                }}
                id="support-menu"
                className="support-menu unauthorized"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="support-icon" title={I18n.t("HEADER.SUPPORT_LINK")}></span>
                <span className="support-link" style={{ marginRight: 24 }}>
                  <HtmlTranslate value="HEADER.SUPPORT_LINK" />
                </span>
              </div>
              <div className="desktopBtnsContainer">
                <a
                  style={{ display: "flex", borderBottom: "none", marginRight: 16, alignItems: "center" }}
                  target="_blank"
                  onClick={handlerAuth}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                >
                  <div className="loginBtn">{I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SIGNIN_BUTTON")}</div>
                </a>
                <NitroButton
                  title={I18n.t("CUSTOMER_APP.NEW_ORDER_PAGE.SIGNUP_BUTTON")}
                  buttonStyleName={"button.orange"}
                  clickHandler={handlerSignUp}
                />
              </div>
              <MobileSignBtn handlerSignUp={handlerSignUp} handlerAuth={handlerAuth} />
            </div>
          )}
          {!notInfo && (
            <div
              className={"more-info"}
              onMouseEnter={() => this.onHoverMoreInfo(true)}
              onMouseLeave={() => this.onHoverMoreInfo(false)}
            >
              <Link style={{ display: "flex", borderBottom: "none" }} target="_blank" to={I18n.t("HEADER.NITRO_LINK")}>
                <IcomoonReact
                  iconSet={iconSet}
                  color={isHoverMoreInfo ? "#FFFFFF" : "#BDBDBD"}
                  size={16}
                  icon={"link"}
                  className="more-info icon"
                />
                <div style={aboutStyle} className="more-info description">
                  {I18n.t("HEADER.ABOUT_NITRO_LINK")}
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
