import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import input from "./css/form-input.css";
import isNumber from "lodash/isNumber";
import Textarea from "react-textarea-autosize";
import Question from "../Title/components";

const INPUT_VALID = "INPUT_VALID";

class FormInput extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.focus && this.input && this.input.focus();
    }, 100);
  }

  render() {
    const {
      status,
      id,
      label,
      styleInput,
      styleContainer,
      onChange,
      value,
      type,
      multiline,
      labelStyle,
      onFocus,
      placeholder,
      question,
      formText,
      currencyType,
      onChangeYen,
      userData,
      textareaStyle,
    } = this.props;

    const inputStyle = !status || status === INPUT_VALID || status === undefined ? "input.valid" : "input.invalid";
    let validationMessage;
    switch (status) {
      case "required":
      case "INPUT_REQUIRED":
        validationMessage = "INPUT_VALIDATIONS.REQUIRED";
        break;
      case "INPUT_INCORRECT_EMAIL":
        validationMessage = "INPUT_VALIDATIONS.INCORRECT_EMAIL_ERROR";
        break;
      case "INVALID_VALUE":
        validationMessage = "INPUT_VALIDATIONS.INVALID_VALUE";
        break;
      case "VALUE_IS_LONG":
        validationMessage = "INPUT_VALIDATIONS.VALUE_IS_LONG";
        break;
      case "GLOSSARY_ALREADY_EXISTS":
        validationMessage = "INPUT_VALIDATIONS.GLOSSARY_ALREADY_EXISTS";
        break;
      case "EMAIL_ALREADY_EXISTS":
        validationMessage = "INPUT_VALIDATIONS.EMAIL_ALREADY_EXISTS";
        break;
      case "STRIPE_INVALID_AMOUNT":
        validationMessage = "INPUT_VALIDATIONS.STRIPE_INVALID_AMOUNT";
        break;
      case "different":
        validationMessage = "TRANSLATOR_APP.SETTINGS_PAGE.PASSWORD_CHANGE.DIFFERENT";
        break;
      case "not-paypal":
        validationMessage = "TRANSLATOR_APP.SETTINGS_PAGE.PAYMENTS.NOT_PAYPAL";
        break;
      case "exist":
        validationMessage = "CUSTOMER_APP.SETTINGS_PAGE.ACCESS_TOKEN.NOTE_BLOCK.VALIDATION_MESSAGE";
        break;
      case "empty":
        validationMessage = "INPUT_VALIDATIONS.FIELD_IS_EMPTY";
        break;
      case "noTextToTranslate":
        validationMessage = "INPUT_VALIDATIONS.NO_TEXT_TO_TRANSLATE";
        break;
      case "unsupportedFileFormat":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_UNSUPPORTED_FILE_FORMAT";
        break;
      case "unsupportedSheetsFormat":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_UNSUPPORTED_SHEETS_FORMAT";
        break;
      case "invalidLink":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_INVALID_LINK";
        break;
      case "noAccessLink":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_NO_ACCESS_LINK";
        break;
      case "noAccessLinkAdmin":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_NO_ACCESS_LINK_ADMIN";
        break;
      case "wrongSegmentNumbersAdmin":
        validationMessage = "ADMIN_APP.ORDER_INFO_PAGE.ERROR_MESSAGE_WRONG_SEGMENT_NUMBERS_ADMIN";
        break;
      case "somethingWentWrong":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_SOMETHING_WENT_WRONG";
        break;
      case "nullFileSize":
        validationMessage = "UPLOAD_FILES_POPUP.ERROR_MESSAGE_FILE_SIZE";
        break;
    }
    const isCoupon = label === I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.REDEEM_CODE");
    const inputValue = isNumber(value) && isCoupon ? "" : value;

    return (
      <FormGroup styleName={"input.container"} controlId={id} style={styleContainer}>
        <FormLabel
          style={labelStyle ? labelStyle : {}}
          styleName={"input.label"}
          /*styleName={"input.form-input-question"}*/
        >
          {label}
          {question && (
            <span style={{ paddingLeft: 5, display: "flex", alignItems: "center" }}>
              <Question question={question} />
            </span>
          )}
        </FormLabel>
        {formText && userData.currency === 1 && this.props.paymentMethod !== "COUPON" ? (
          <p styleName={"input.label--text"}>{formText}</p>
        ) : (
          ""
        )}
        {
          <div styleName={"input.form-wrapper"}>
            {multiline ? (
              <Textarea
                ref={input => (this.input = input)}
                styleName={inputStyle}
                style={textareaStyle} //[WIP] Uncomment after Textarea Fix
                onChange={onChange}
                onFocus={onFocus}
                value={inputValue}
                name={id}
                type={type}
                minRows={2}
              />
            ) : currencyType && userData.currency === 1 ? (
              <div styleName={"input.form-input-container"}>
                <div styleName={"input.input-container"}>
                  <FormControl
                    componentclass={"input"}
                    ref={input => (this.input = input)}
                    styleName={inputStyle}
                    style={{ width: "98px", paddingRight: "15px" }}
                    onChange={onChange}
                    onFocus={onFocus}
                    value={inputValue}
                    placeholder={placeholder}
                    name={id}
                    type={type}
                  />
                </div>
                <div styleName={"input.input-container input.input-container--yen"}>
                  <FormControl
                    componentclass={"input"}
                    ref={input => (this.input = input)}
                    styleName={`${inputStyle} input.input--yen`}
                    style={{ width: "98px", paddingRight: "15px" }}
                    onChange={onChangeYen}
                    onFocus={onFocus}
                    value={this.props.inputValueYen}
                    placeholder={placeholder}
                    name={id}
                    type={type}
                  />
                </div>
              </div>
            ) : (
              <FormControl
                componentclass={"input"}
                ref={input => (this.input = input)}
                styleName={inputStyle}
                style={styleInput}
                onChange={onChange}
                onFocus={onFocus}
                value={inputValue}
                placeholder={placeholder}
                name={id}
                type={type}
              />
            )}
            {this.props.equivalentDisplay && (
              <div
                styleName={"input.equivalent"}
                style={{
                  display: userData.currency === 1 && this.props.paymentMethod === "WIRE_YEN" ? "none" : "block",
                }}
              >
                {I18n.t("CUSTOMER_APP.BALANCE_PAGE.ADD_FUNDS.EQUIVALENT", {
                  equivalent: this.props.equivalent,
                })}
              </div>
            )}
            {currencyType && userData.currency === 1 && (
              <p styleName={"input.input-currency-exchange"}>$1.00 = ¥{this.props.exchangeRate}</p>
            )}
          </div>
        }
        {validationMessage && <p styleName={"input.validation-message"}>{I18n.t(validationMessage)}</p>}
      </FormGroup>
    );
  }
}

FormInput.propTypes = {};

export default FormInput;
