var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { usePopoverContext } from "../models";
export var PopoverClose = forwardRef(function PopoverClose(props, ref) {
    var setOpen = usePopoverContext().setOpen;
    var onCustomComponent = props.onCustomComponent;
    var onClickButton = function (event) {
        var _a;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, event);
        setOpen(false);
    };
    if (onCustomComponent) {
        return onCustomComponent(function (event) {
            onClickButton(event);
        });
    }
    return _jsx("button", __assign({ ref: ref, type: "button" }, props, { onClick: onClickButton }));
});
