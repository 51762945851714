var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { arrow, autoUpdate, flip as flipMiddleware, offset as offsetMiddleware, safePolygon, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, } from "@floating-ui/react";
import { useMemo, useRef, useState } from "react";
export var useTooltip = function (_a) {
    var flip = _a.flip, offset = _a.offset, placement = _a.placement;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var arrowRef = useRef(null);
    var data = useFloating({
        middleware: [
            offsetMiddleware(offset),
            flipMiddleware({
                fallbackPlacements: flip.fallbackPlacement,
                // this property is used, because we have a sticky "Header" in application
                // for now "floating-ui" (version 0.26.2) does not support components collision resolving: https://floating-ui.com/docs/misc#z-index-stacking
                padding: flip.padding,
            }),
            arrowRef && arrow({ element: arrowRef, padding: 8 }),
        ],
        onOpenChange: setIsOpen,
        open: isOpen,
        placement: placement,
        whileElementsMounted: autoUpdate,
    });
    var context = data.context;
    var hover = useHover(context, {
        move: false,
        handleClose: safePolygon(),
    });
    var focus = useFocus(context);
    var dismiss = useDismiss(context);
    var role = useRole(context, { role: "tooltip" });
    var interactions = useInteractions([hover, focus, dismiss, role]);
    return useMemo(function () { return (__assign(__assign({ arrowRef: arrowRef, isOpen: isOpen, setIsOpen: setIsOpen }, interactions), data)); }, [isOpen, setIsOpen, interactions, data]);
};
