import React from "react";
import { createRoot } from "react-dom/client";
import { configureFormsStore } from "./store/configureStore";
import FormsRoot from "./containers/FormsRoot";
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";
import setupLanguage from "./helpers/setupLanguage";
import setupTrackers from "./helpers/setupTrackers";

import { commonTranslations } from "./locales";

const language = setupLanguage();
const store = configureFormsStore();

setupTrackers();
syncTranslationWithStore(store);

store.dispatch(loadTranslations(commonTranslations));
store.dispatch(setLocale(language));

const container = document.getElementById("app-container");
const root = createRoot(container);
root.render(<FormsRoot store={store} />);
