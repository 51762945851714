var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SortOrder, Page, Empty } from "@alconost/nitro-services-api/src/proto/base_pb";
import { SortOrderField, ListOrdersRequest, GetOrderRequest, GetNextOrderRequest, OrderTableSort, OrderIds, OrderId, RevisionOrderRequest, } from "@alconost/nitro-services-api/src/proto/order_service_pb";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { getCorrectStatusesList } from "../OrderServices/helpers";
import { InsertAiTextRequest, UpdatePauseRequest, } from "@alconost/nitro-services-api/src/proto/order_service_admin_pb";
import { OrderServiceAdminClient } from "@alconost/nitro-services-api/src/proto/order_service_admin_grpc_web_pb";
export var listOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, createdAfter = _b.createdAfter, createdBefore = _b.createdBefore, completedAfter = _b.completedAfter, completedBefore = _b.completedBefore, workspace = _b.workspace;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                request.setCreatedBefore(createdBefore);
                request.setCreatedAfter(createdAfter);
                request.setCompletedBefore(completedBefore);
                request.setCompletedAfter(completedAfter);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "listOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersWithDraftAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, workspace = _b.workspace;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "listOrdersWithDraftAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getOrderAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, needTags = _b.needTags, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetOrderRequest();
                request.setOrderId(orderId);
                request.setNeedTags(needTags);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "getOrderAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextAdminOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, orderSortList, metadata;
    var orderId = _b.orderId, next = _b.next, status = _b.status, sortList = _b.sortList, search = _b.search, locale = _b.locale, isUnread = _b.isUnread, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextOrderRequest();
                orderSortList = sortList.reduce(function (accumulator, element) {
                    var _a;
                    var orderTableSort = new OrderTableSort();
                    orderTableSort.setStatus(element.status);
                    //@ts-ignore
                    orderTableSort.setField(SortOrderField[element.field]);
                    //@ts-ignore
                    orderTableSort.setOrder(SortOrder[element.order]);
                    orderTableSort.setSourceLanguagesList(element.sourceLanguage);
                    orderTableSort.setTargetLanguagesList(element.targetLanguage);
                    orderTableSort.setFormat((_a = element.format) === null || _a === void 0 ? void 0 : _a.value);
                    orderTableSort.setCreatedBefore(element.createdBefore);
                    orderTableSort.setCreatedAfter(element.createdAfter);
                    orderTableSort.setCompletedBefore(element.completedBefore);
                    orderTableSort.setCompletedAfter(element.completedAfter);
                    orderTableSort.setWorkspaceName(element.workspaceName);
                    accumulator.push(orderTableSort);
                    return accumulator;
                }, []);
                request.setOrderId(orderId);
                request.setNext(next);
                request.setStatus(status);
                request.setSortsList(orderSortList);
                request.setSearch(search);
                request.setLocale(locale);
                request.setIsUnread(isUnread);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "getNextAdminOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getAdminQueueStatusRequest = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var request, metadata;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _a.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "getAdminQueueStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var skipProgressOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "skipProgressOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var insertAITextRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, id = _b.id, targetText = _b.targetText;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new InsertAiTextRequest();
                request.setOrderid(id);
                request.setTargetSegmentsList(targetText);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "insertAiText",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var restartFileBuildRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, orderId = _b.orderId;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "restartFileBuild",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var setOrderUnreadByAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "setOrderUnreadByAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updatePauseAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, paused = _b.paused, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdatePauseRequest();
                request.setOrderIdsList(orderIds);
                request.setPaused(paused);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "updatePauseAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startAdminEditDraftOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "startAdminEditDraftOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startEditorEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "startEditorEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startAdminEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "startAdminEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var revisionOrderAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RevisionOrderRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "revisionOrderAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var cancelOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceAdminClient,
                        methodName: "cancelOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
