var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from "@floating-ui/react";
import { forwardRef } from "react";
import { useDialogContext } from "../../models";
import styles from "./styles.module.css";
export var DialogContent = forwardRef(function (props, propRef) {
    var _a = useDialogContext(), context = _a.context, otherContext = __rest(_a, ["context"]);
    var ref = useMergeRefs([otherContext.refs.setFloating, propRef]);
    if (!context.open)
        return null;
    var handleClick = function (event) {
        event.stopPropagation();
    };
    return (_jsx(FloatingPortal, { children: _jsx(FloatingOverlay, { className: "".concat(styles.dialogOverlay), style: props.style, lockScroll: true, children: _jsx(FloatingFocusManager, { context: context, children: _jsx("div", __assign({ ref: ref, "aria-describedby": otherContext.descriptionId, "aria-labelledby": otherContext.labelId }, otherContext.getFloatingProps(props), { onClick: handleClick, children: props.children })) }) }) }));
});
