import React, { Component } from "react";

import classnames from "classnames";

export default class AppSuccess extends Component {
  selfClose = () => {
    const { onClose } = this.props;
    onClose ? onClose() : (document.getElementById("app-success").style.display = "none");
  };

  render() {
    const { onClose, isOrange, message, onBtn, btnTitle } = this.props;
    const display = this.props.display ? "flex" : "none";
    let mainClasses = classnames("app-success with-fade-in with-fade-out", {
      "app-success--orange": isOrange,
    });
    return (
      <div id="app-success" className={mainClasses} style={{ display: display }}>
        <div className="error-container">
          <span className="error-text">{message}</span>

          {onBtn && (
            <div className="error-refresh-link" onClick={onBtn}>
              {btnTitle}
            </div>
          )}
          {onClose && <a href="#" className="error-close-link" onClick={this.selfClose}></a>}
        </div>
      </div>
    );
  }
}
