import React, { Component } from "react";
import HtmlTranslate from "../../components/HtmlTranslate";

import "../../css/deprecated/app-error.css";
import IcomoonReact from "icomoon-react";
import iconSet from "../../images/selection.json";

export default class AppError extends Component {
  selfClose = () => {
    const { onClose } = this.props;
    onClose ? onClose() : (document.getElementById("app-error").style.display = "none");
  };

  render() {
    const display = this.props.display ? "flex" : "none";
    return (
      <div id="app-error" className="app-error with-fade-in with-fade-out" style={{ display: display }}>
        <div className="error-container">
          <span className="error-text">{this.props.message}</span>
          <div
            className="error-refresh-link"
            onClick={function () {
              window.location.reload();
            }}
          >
            <HtmlTranslate value="ERRORS.COMMON_REFRESH_BUTTON" />
          </div>
          <a href="#" className="error-close-link" onClick={this.selfClose}>
            <IcomoonReact iconSet={iconSet} color={"#FFFFFF"} size={14} icon={"close"} />
          </a>
        </div>
      </div>
    );
  }
}
