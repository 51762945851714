var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Empty, Page, SortOrder } from "@alconost/nitro-services-api/src/proto/base_pb";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { WorkspaceServiceClient } from "@alconost/nitro-services-api/src/proto/workspace_service_grpc_web_pb";
import { AcceptInviteRequest, CreateWorkspaceRequest, RefuseInviteRequest, GetWorkspaceRequest, ListWorkspaceRequest, SortWorkspaceField, UpdateAdminWorkspaceRequest, WorkspaceId, UpdateWorkspaceAdminRequest, AddInvitesAdminRequest, DeleteUsersAdminRequest, WorkspaceUser, UpdateUsersWorkspaceRequest, UpdateEmailNotificationsRequest, UpdateWorkspaceMemberRequest, DisableWorkspaceEmailNotifications, WorkspaceInviteInfoRequest, } from "@alconost/nitro-services-api/src/proto/workspace_service_pb";
export var listWorkspacesAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var limit = _b.limit, offset = _b.offset, search = _b.search, sortField = _b.sortField, sortOrder = _b.sortOrder, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListWorkspaceRequest();
                page = new Page();
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search);
                request.setField(SortWorkspaceField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "listWorkspacesAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createWorkspaceRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var name = _b.name, link = _b.link, invites = _b.invites, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreateWorkspaceRequest();
                request.setName(name);
                request.setLink(link);
                request.setInvitesList(invites);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "createWorkspace",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getWorkspacesAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new WorkspaceId();
                request.setWorkspaceid(workspaceId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "getWorkspacesAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getWorkspaceRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var id = _b.id, link = _b.link, name = _b.name, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetWorkspaceRequest();
                if (id !== undefined) {
                    request.setId(id);
                }
                else if (link !== undefined) {
                    request.setLink(link);
                }
                else if (name !== undefined) {
                    request.setName(name);
                }
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "getWorkspace",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateAdminWorkspaceRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, name = _b.name, link = _b.link, billTo = _b.billTo, balanceOverdraftAllowed = _b.balanceOverdraftAllowed, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateAdminWorkspaceRequest();
                request.setWorkspaceId(workspaceId);
                request.setName(name);
                request.setLink(link);
                request.setBillTo(billTo);
                request.setBalanceOverdraftAllowed(balanceOverdraftAllowed);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateAdminWorkspace",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listWorkspaceNamesRequest = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var request, metadata;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _a.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "listWorkspaceNames",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteInviteRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RefuseInviteRequest();
                request.setWorkspaceId(workspaceId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "refuseInvite",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var acceptInviteRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new AcceptInviteRequest();
                request.setWorkspaceId(workspaceId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "acceptInvite",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateWorkspaceAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, name = _b.name, link = _b.link, currency = _b.currency, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateWorkspaceAdminRequest();
                request.setWorkspaceId(workspaceId);
                request.setName(name);
                request.setLink(link);
                request.setCurrency(currency);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateWorkspaceAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var addInvitesAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, emails = _b.emails, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new AddInvitesAdminRequest();
                request.setWorkspaceId(workspaceId);
                request.setEmailsList(emails);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "addInvitesAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteUsersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, _c = _b.invitations, invitations = _c === void 0 ? [] : _c, _d = _b.users, users = _d === void 0 ? [] : _d, token = _b.token;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                request = new DeleteUsersAdminRequest();
                request.setWorkspaceid(workspaceId);
                if (invitations.length) {
                    request.setInvitationsList(invitations);
                }
                if (users.length) {
                    request.setUsersList(users);
                }
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _e.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "deleteUsersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
// Функция для выполнения запроса updateUsersWorkspaceAdmin
export var updateUsersWorkspaceAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, userList, metadata;
    var workspaceId = _b.workspaceId, users = _b.users, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateUsersWorkspaceRequest();
                request.setWorkspaceid(workspaceId);
                userList = users.map(function (user) {
                    var workspaceUser = new WorkspaceUser();
                    workspaceUser.setId(user.id);
                    workspaceUser.setRole(user.role);
                    return workspaceUser;
                });
                request.setUsersList(userList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateUsersWorkspaceAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateEmailNotificationsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, notification, metadata;
    var workspaceId = _b.workspaceId, disableEmailNotifications = _b.disableEmailNotifications, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateEmailNotificationsRequest();
                request.setWorkspaceId(workspaceId);
                notification = new DisableWorkspaceEmailNotifications();
                notification.setCancelled(!disableEmailNotifications.cancelled);
                notification.setCompleted(!disableEmailNotifications.completed);
                notification.setEdited(!disableEmailNotifications.edited);
                notification.setRevised(!disableEmailNotifications.revised);
                request.setDisableEmailNotifications(notification);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateEmailNotifications",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateWorkspaceMemberRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, currency = _b.currency, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateWorkspaceMemberRequest();
                request.setWorkspaceId(workspaceId);
                request.setCurrency(currency);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateWorkspaceMember",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var deleteWorkspaceMembersRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, invitationsList = _b.invitationsList, usersList = _b.usersList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DeleteUsersAdminRequest();
                request.setWorkspaceid(workspaceId);
                request.setInvitationsList(invitationsList);
                request.setUsersList(usersList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "deleteWorkspaceMembersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateUsersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var workspaceId = _b.workspaceId, usersList = _b.usersList, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateUsersWorkspaceRequest();
                request.setWorkspaceid(workspaceId);
                request.setUsersList(usersList.map(function (user) {
                    var workspaceUser = new WorkspaceUser();
                    workspaceUser.setId(user.id);
                    workspaceUser.setRole(user.role);
                    return workspaceUser;
                }));
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "updateUsersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getInviteInfoRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata, _c;
    var code = _b.code, token = _b.token;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                request = new WorkspaceInviteInfoRequest();
                request.setCode(code);
                if (!token) return [3 /*break*/, 2];
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                _c = _d.sent();
                return [3 /*break*/, 3];
            case 2:
                _c = undefined;
                _d.label = 3;
            case 3:
                metadata = _c;
                return [2 /*return*/, grpcRequest({
                        clientService: WorkspaceServiceClient,
                        methodName: "getInviteInfo",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
