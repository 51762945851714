export var OrderTypes;
(function (OrderTypes) {
    OrderTypes["GET_FILE_METADATA_REQUEST"] = "GET_FILE_METADATA_REQUEST";
    OrderTypes["GET_FILE_METADATA_SUCCESS"] = "GET_FILE_METADATA_SUCCESS";
    OrderTypes["GET_ORDER_FILE_REQUEST"] = "GET_ORDER_FILE_REQUEST";
    OrderTypes["GET_ORDER_FILE_SUCCESS"] = "GET_ORDER_FILE_SUCCESS";
    OrderTypes["CREATE_TEMPLATE_REQUEST"] = "CREATE_TEMPLATE_REQUEST";
    OrderTypes["CREATE_TEMPLATE_SUCCESS"] = "CREATE_TEMPLATE_SUCCESS";
    OrderTypes["DELETE_TEMPLATE_REQUEST"] = "DELETE_TEMPLATE_REQUEST";
    OrderTypes["DELETE_TEMPLATE_SUCCESS"] = "DELETE_TEMPLATE_SUCCESS";
    OrderTypes["SEND_MAILS_REQUEST"] = "SEND_MAILS_REQUEST";
    OrderTypes["SEND_MAILS_SUCCESS"] = "SEND_MAILS_SUCCESS";
    OrderTypes["GET_TEMPLATES_REQUEST"] = "GET_TEMPLATES_REQUEST";
    OrderTypes["GET_TEMPLATES_SUCCESS"] = "GET_TEMPLATES_SUCCESS";
    OrderTypes["ADD_STAR_REQUEST"] = "ADD_STAR_REQUEST";
    OrderTypes["ADD_STAR_SUCCESS"] = "ADD_STAR_SUCCESS";
    OrderTypes["GET_ORDER_ADMIN_REQUEST"] = "GET_ORDER_ADMIN_REQUEST";
    OrderTypes["GET_ORDER_ADMIN_SUCCESS"] = "GET_ORDER_ADMIN_SUCCESS";
    OrderTypes["GET_ORDER_TRANSLATOR_REQUEST"] = "GET_ORDER_TRANSLATOR_REQUEST";
    OrderTypes["GET_ORDER_TRANSLATOR_SUCCESS"] = "GET_ORDER_TRANSLATOR_SUCCESS";
    OrderTypes["GET_ORDER_CUSTOMER_REQUEST"] = "GET_ORDER_CUSTOMER_REQUEST";
    OrderTypes["GET_ORDER_CUSTOMER_SUCCESS"] = "GET_ORDER_CUSTOMER_SUCCESS";
    OrderTypes["SET_ORDER_UNREAD_BY_ADMIN_REQUEST"] = "SET_ORDER_UNREAD_BY_ADMIN_REQUEST";
    OrderTypes["SET_ORDER_UNREAD_BY_ADMIN_SUCCESS"] = "SET_ORDER_UNREAD_BY_ADMIN_SUCCESS";
    OrderTypes["ACCEPT_ORDER_REQUEST"] = "ACCEPT_ORDER_REQUEST";
    OrderTypes["ACCEPT_ORDER_SUCCESS"] = "ACCEPT_ORDER_SUCCESS";
    OrderTypes["GET_ORDER_REQUEST"] = "GET_ORDER_REQUEST";
    OrderTypes["GET_ORDER_SUCCESS"] = "GET_ORDER_SUCCESS";
    OrderTypes["CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_REQUEST"] = "CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_REQUEST";
    OrderTypes["CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_SUCCESS"] = "CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT_SUCCESS";
    OrderTypes["START_ADMIN_EDIT_DRAFT_ORDER_REQUEST"] = "START_ADMIN_EDIT_DRAFT_ORDER_REQUEST";
    OrderTypes["START_ADMIN_EDIT_DRAFT_ORDER_SUCCESS"] = "START_ADMIN_EDIT_DRAFT_ORDER_SUCCESS";
    OrderTypes["START_TRANSLATOR_EDIT_ORDER_REQUEST"] = "START_TRANSLATOR_EDIT_ORDER_REQUEST";
    OrderTypes["START_TRANSLATOR_EDIT_ORDER_SUCCESS"] = "START_TRANSLATOR_EDIT_ORDER_SUCCESS";
    OrderTypes["START_EDITOR_EDIT_ORDER_REQUEST"] = "START_EDITOR_EDIT_ORDER_REQUEST";
    OrderTypes["START_EDITOR_EDIT_ORDER_SUCCESS"] = "START_EDITOR_EDIT_ORDER_SUCCESS";
    OrderTypes["START_ADMIN_EDIT_ORDER_REQUEST"] = "START_ADMIN_EDIT_ORDER_REQUEST";
    OrderTypes["START_ADMIN_EDIT_ORDER_SUCCESS"] = "START_ADMIN_EDIT_ORDER_SUCCESS";
    OrderTypes["START_REVISION_ORDER_REQUEST"] = "START_REVISION_ORDER_REQUEST";
    OrderTypes["START_REVISION_ORDER_SUCCESS"] = "START_REVISION_ORDER_SUCCESS";
    OrderTypes["SKIP_PROGRESS_ORDERS_TRANSLATOR_REQUEST"] = "SKIP_PROGRESS_ORDERS_TRANSLATOR_REQUEST";
    OrderTypes["SKIP_PROGRESS_ORDERS_TRANSLATOR_SUCCESS"] = "SKIP_PROGRESS_ORDERS_TRANSLATOR_SUCCESS";
    OrderTypes["SKIP_PROGRESS_ORDERS_ADMIN_REQUEST"] = "SKIP_PROGRESS_ORDERS_ADMIN_REQUEST";
    OrderTypes["SKIP_PROGRESS_ORDERS_ADMIN_SUCCESS"] = "SKIP_PROGRESS_ORDERS_ADMIN_SUCCESS";
    OrderTypes["CANCEL_ORDERS_ADMIN_REQUEST"] = "CANCEL_ORDERS_ADMIN_REQUEST";
    OrderTypes["CANCEL_ORDERS_ADMIN_SUCCESS"] = "CANCEL_ORDERS_ADMIN_SUCCESS";
    OrderTypes["CANCEL_ORDERS_CUSTOMER_REQUEST"] = "CANCEL_ORDERS_CUSTOMER_REQUEST";
    OrderTypes["CANCEL_ORDERS_CUSTOMER_SUCCESS"] = "CANCEL_ORDERS_CUSTOMER_SUCCESS";
    OrderTypes["ORDERS_VIEWED_REQUEST"] = "ORDERS_VIEWED_REQUEST";
    OrderTypes["ORDERS_VIEWED_SUCCESS"] = "ORDERS_VIEWED_SUCCESS";
    OrderTypes["EXPORT_ORDERS_CUSTOMER_REQUEST"] = "EXPORT_ORDERS_CUSTOMER_REQUEST";
    OrderTypes["EXPORT_ORDERS_CUSTOMER_SUCCESS"] = "EXPORT_ORDERS_CUSTOMER_SUCCESS";
    OrderTypes["EXPORT_ORDERS_ADMIN_REQUEST"] = "EXPORT_ORDERS_ADMIN_REQUEST";
    OrderTypes["EXPORT_ORDERS_ADMIN_SUCCESS"] = "EXPORT_ORDERS_ADMIN_SUCCESS";
    OrderTypes["GET_QUEUE_STATUS_REQUEST"] = "GET_QUEUE_STATUS_REQUEST";
    OrderTypes["GET_QUEUE_STATUS_SUCCESS"] = "GET_QUEUE_STATUS_SUCCESS";
    OrderTypes["GET_ADMIN_QUEUE_STATUS_REQUEST"] = "GET_ADMIN_QUEUE_STATUS_REQUEST";
    OrderTypes["GET_ADMIN_QUEUE_STATUS_SUCCESS"] = "GET_ADMIN_QUEUE_STATUS_SUCCESS";
    OrderTypes["ADD_COMMENT_ADMIN_REQUEST"] = "ADD_COMMENT_ADMIN_REQUEST";
    OrderTypes["ADD_COMMENT_ADMIN_SUCCESS"] = "ADD_COMMENT_ADMIN_SUCCESS";
    OrderTypes["ADD_COMMENT_TRANSLATOR_REQUEST"] = "ADD_COMMENT_TRANSLATOR_REQUEST";
    OrderTypes["ADD_COMMENT_TRANSLATOR_SUCCESS"] = "ADD_COMMENT_TRANSLATOR_SUCCESS";
    OrderTypes["ADD_COMMENT_REQUEST"] = "ADD_COMMENT_REQUEST";
    OrderTypes["ADD_COMMENT_SUCCESS"] = "ADD_COMMENT_SUCCESS";
    OrderTypes["GET_NEXT_ADMIN_ORDER_NEW_REQUEST"] = "GET_NEXT_ADMIN_ORDER_NEW_REQUEST";
    OrderTypes["GET_NEXT_ADMIN_ORDER_NEW_SUCCESS"] = "GET_NEXT_ADMIN_ORDER_NEW_SUCCESS";
    OrderTypes["GET_NEXT_CUSTOMER_ORDER_NEW_REQUEST"] = "GET_NEXT_CUSTOMER_ORDER_NEW_REQUEST";
    OrderTypes["GET_NEXT_TRANSLATOR_ORDER_NEW_REQUEST"] = "GET_NEXT_TRANSLATOR_ORDER_NEW_REQUEST";
    OrderTypes["GET_NEXT_CUSTOMER_ORDER_NEW_SUCCESS"] = "GET_NEXT_CUSTOMER_ORDER_NEW_SUCCESS";
    OrderTypes["GET_NEXT_TRANSLATOR_ORDER_NEW_SUCCESS"] = "GET_NEXT_TRANSLATOR_ORDER_NEW_SUCCESS";
    OrderTypes["GET_NEXT_DESKTOP_ORDER_REQUEST"] = "GET_NEXT_DESKTOP_ORDER_REQUEST";
    OrderTypes["DETECT_LANGUAGE_REQUEST"] = "DETECT_LANGUAGE_REQUEST";
    OrderTypes["READ_MESSAGES_REQUEST"] = "READ_MESSAGES_REQUEST";
    OrderTypes["READ_MESSAGES_SUCCESS"] = "READ_MESSAGES_SUCCESS";
    OrderTypes["PROLONG_ORDER_REQUEST"] = "PROLONG_ORDER_REQUEST";
    OrderTypes["PROLONG_ORDER_SUCCESS"] = "PROLONG_ORDER_SUCCESS";
    OrderTypes["COMPLETE_ORDER_REQUEST"] = "COMPLETE_ORDER_REQUEST";
    OrderTypes["COMPLETE_ORDER_SUCCESS"] = "COMPLETE_ORDER_SUCCESS";
    OrderTypes["UPDATE_ARCHIVE_REQUEST"] = "UPDATE_ARCHIVE_REQUEST";
    OrderTypes["UPDATE_ARCHIVE_SUCCESS"] = "UPDATE_ARCHIVE_SUCCESS";
    OrderTypes["ACCEPT_GROUP_REQUEST"] = "ACCEPT_GROUP_REQUEST";
    OrderTypes["ACCEPT_GROUP_SUCCESS"] = "ACCEPT_GROUP_SUCCESS";
    OrderTypes["FINISH_EDIT_ORDER_REQUEST"] = "FINISH_EDIT_ORDER_REQUEST";
    OrderTypes["FINISH_EDIT_ORDER_SUCCESS"] = "FINISH_EDIT_ORDER_SUCCESS";
    OrderTypes["FINISH_EDITOR_ORDER_REQUEST"] = "FINISH_EDITOR_ORDER_REQUEST";
    OrderTypes["FINISH_EDITOR_ORDER_SUCCESS"] = "FINISH_EDITOR_ORDER_SUCCESS";
    OrderTypes["REVISION_ORDER_REQUEST"] = "REVISION_ORDER_REQUEST";
    OrderTypes["REVISION_ORDER_SUCCESS"] = "REVISION_ORDER_SUCCESS";
    OrderTypes["REVISION_ORDER_ADMIN_REQUEST"] = "REVISION_ORDER_ADMIN_REQUEST";
    OrderTypes["REVISION_ORDER_ADMIN_SUCCESS"] = "REVISION_ORDER_ADMIN_SUCCESS";
    OrderTypes["FINISH_REVISION_ORDER_REQUEST"] = "FINISH_REVISION_ORDER_REQUEST";
    OrderTypes["FINISH_REVISION_ORDER_SUCCESS"] = "FINISH_REVISION_ORDER_SUCCESS";
    OrderTypes["UPDATE_PAUSE_ADMIN_REQUEST"] = "UPDATE_PAUSE_ADMIN_REQUEST";
    OrderTypes["UPDATE_PAUSE_ADMIN_SUCCESS"] = "UPDATE_PAUSE_ADMIN_SUCCESS";
    OrderTypes["SAVE_DRAFT_REQUEST"] = "SAVE_DRAFT_REQUEST";
    OrderTypes["SAVE_DRAFT_SUCCESS"] = "SAVE_DRAFT_SUCCESS";
    OrderTypes["UPDATE_ADVANCED_OPTIONS_SUCCESS"] = "UPDATE_ADVANCED_OPTIONS_SUCCESS";
    OrderTypes["UPDATE_ADVANCED_OPTIONS_REQUEST"] = "UPDATE_ADVANCED_OPTIONS_REQUEST";
    OrderTypes["GET_UNREAD_STATUS_REQUEST"] = "GET_UNREAD_STATUS_REQUEST";
    OrderTypes["GET_UNREAD_STATUS_SUCCESS"] = "GET_UNREAD_STATUS_SUCCESS";
    OrderTypes["LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_REQUEST"] = "LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_REQUEST";
    OrderTypes["LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_SUCCESS"] = "LIST_ACTION_REQUIRED_ORDERS_TRANSLATOR_SUCCESS";
    OrderTypes["CREATE_ORDER_REQUEST"] = "CREATE_ORDER_REQUEST";
    OrderTypes["CREATE_ORDER_SUCCESS"] = "CREATE_ORDER_SUCCESS";
    OrderTypes["LIST_ORDERS_WITH_DRAFT_ADMIN_REQUEST"] = "LIST_ORDERS_WITH_DRAFT_ADMIN_REQUEST";
    OrderTypes["LIST_ORDERS_WITH_DRAFT_ADMIN_SUCCESS"] = "LIST_ORDERS_WITH_DRAFT_ADMIN_SUCCESS";
    OrderTypes["LIST_ORDERS_WITH_DRAFT_TRANSLATOR_SUCCESS"] = "LIST_ORDERS_WITH_DRAFT_TRANSLATOR_SUCCESS";
    OrderTypes["LIST_ORDERS_WITH_DRAFT_TRANSLATOR_REQUEST"] = "LIST_ORDERS_WITH_DRAFT_TRANSLATOR_REQUEST";
    OrderTypes["GET_DONE_ORDERS_REQUEST"] = "GET_DONE_ORDERS_REQUEST";
    OrderTypes["GET_DONE_ORDERS_SUCCESS"] = "GET_DONE_ORDERS_SUCCESS";
    OrderTypes["GET_INCOMPLETE_ORDERS_REQUEST"] = "GET_INCOMPLETE_ORDERS_REQUEST";
    OrderTypes["GET_INCOMPLETE_ORDERS_SUCCESS"] = "GET_INCOMPLETE_ORDERS_SUCCESS";
    OrderTypes["GET_QUEUE_ORDERS_SUCCESS"] = "GET_QUEUE_ORDERS_SUCCESS";
    OrderTypes["GET_QUEUE_ORDERS_REQUEST"] = "GET_QUEUE_ORDERS_REQUEST";
    OrderTypes["GET_ARCHIVED_ORDERS_REQUEST"] = "GET_ARCHIVED_ORDERS_REQUEST";
    OrderTypes["GET_ARCHIVED_ORDERS_SUCCESS"] = "GET_ARCHIVED_ORDERS_SUCCESS";
    OrderTypes["RESTART_FILE_BUILD_REQUEST"] = "RESTART_FILE_BUILD_REQUEST";
    OrderTypes["RESTART_FILE_BUILD_SUCCESS"] = "RESTART_FILE_BUILD_SUCCESS";
    OrderTypes["GET_NEXT_DESKTOP_ORDER_SUCCESS"] = "GET_NEXT_DESKTOP_ORDER_SUCCESS";
    OrderTypes["GET_LEXIQA_REQUEST"] = "GET_LEXIQA_REQUEST";
    OrderTypes["GET_LEXIQA_SUCCESS"] = "GET_LEXIQA_SUCCESS";
    OrderTypes["GET_LEXIQA_FAILED"] = "GET_LEXIQA_FAILED";
})(OrderTypes || (OrderTypes = {}));
