var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OrderServiceCustomerClient } from "@alconost/nitro-services-api/src/proto/order_service_customer_grpc_web_pb";
import { AdvancedOptions, GetNextOrderRequest, GetOrderRequest, ListOrdersRequest, OrderIds, OrderTableSort, OrderTranslationType, RevisionOrderRequest, SortOrderField, } from "@alconost/nitro-services-api/src/proto/order_service_pb";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { SortOrder, Page } from "@alconost/nitro-services-api/src/proto/base_pb";
import { ListCompletedOrdersCustomerRequest, DetectLanguageRequest, UpdateArchiveRequest, CreateOrderRequest, } from "@alconost/nitro-services-api/src/proto/order_service_customer_pb";
import { limit } from "stringz";
import { getCorrectStatusesList } from "../OrderServices/helpers";
import { getCid } from "../../helpers/googleAnalyticsUtils";
export var createOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, advancedOptions, cid, newLanguageList, metadata;
    var translationService = _b.translationService, sourceText = _b.sourceText, uuids = _b.uuids, sourceLanguage = _b.sourceLanguage, hint = _b.hint, targetLanguageList = _b.targetLanguageList, attachments = _b.attachments, discountCode = _b.discountCode, waitOrdersGroupToNotified = _b.waitOrdersGroupToNotified, advancedCategoryValue = _b.advancedCategoryValue, maxTranslationsLength = _b.maxTranslationsLength, type = _b.type, draftId = _b.draftId, needApplyTmPmDiscount = _b.needApplyTmPmDiscount, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreateOrderRequest();
                advancedOptions = new AdvancedOptions();
                cid = getCid();
                sourceText && request.setSourceText(sourceText);
                uuids && request.setFileUuid(uuids);
                request.setSourceLanguage(sourceLanguage);
                request.setHint(hint);
                newLanguageList = targetLanguageList.reduce(function (accumulator, element) {
                    accumulator.push(element.value);
                    return accumulator;
                }, []);
                request.setTargetLanguageList(newLanguageList);
                request.setAttachmentsList(attachments);
                //@ts-ignore
                request.setCid(cid);
                request.setDiscountCode(discountCode);
                request.setWaitordersgrouptonotified(waitOrdersGroupToNotified);
                !!draftId && request.setDraftId(draftId);
                request.setNeedApplyTmPmDiscount(needApplyTmPmDiscount);
                advancedOptions.setTranslationCategory(advancedCategoryValue);
                advancedOptions.setTranslationType(OrderTranslationType[type]);
                advancedOptions.setMaxLength(maxTranslationsLength);
                request.setAdvancedOptions(advancedOptions);
                request.setTranslationService(translationService);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "createOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "listOrdersCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listCompletedOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, listCompletedOrdersCustomerRequest, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, archived = _b.archived, createdAfter = _b.createdAfter, createdBefore = _b.createdBefore, completedAfter = _b.completedAfter, completedBefore = _b.completedBefore;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                listCompletedOrdersCustomerRequest = new ListCompletedOrdersCustomerRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setCreatedBefore(createdBefore);
                request.setCreatedAfter(createdAfter);
                request.setCompletedBefore(completedBefore);
                request.setCompletedAfter(completedAfter);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                listCompletedOrdersCustomerRequest.setRequest(request);
                listCompletedOrdersCustomerRequest.setArchived(archived);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                // console.log("listCompletedOrdersCustomerRequest", request.toObject());
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "listCompletedOrdersCustomer",
                        protoRequest: listCompletedOrdersCustomerRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getOrderCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, needTags = _b.needTags, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetOrderRequest();
                request.setOrderId(orderId);
                request.setNeedTags(needTags);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "getOrderCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextCustomerOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, orderSortList, metadata;
    var orderId = _b.orderId, next = _b.next, status = _b.status, sortList = _b.sortList, search = _b.search, locale = _b.locale, isUnread = _b.isUnread, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextOrderRequest();
                orderSortList = sortList.reduce(function (accumulator, element) {
                    var _a;
                    var orderTableSort = new OrderTableSort();
                    orderTableSort.setStatus(element.status);
                    //@ts-ignore
                    orderTableSort.setField(SortOrderField[element.field]);
                    //@ts-ignore
                    orderTableSort.setOrder(SortOrder[element.order]);
                    orderTableSort.setSourceLanguagesList(element.sourceLanguage);
                    orderTableSort.setTargetLanguagesList(element.targetLanguage);
                    orderTableSort.setFormat((_a = element.format) === null || _a === void 0 ? void 0 : _a.value);
                    orderTableSort.setCreatedBefore(element.createdBefore);
                    orderTableSort.setCreatedAfter(element.createdAfter);
                    orderTableSort.setCompletedBefore(element.completedBefore);
                    orderTableSort.setCompletedAfter(element.completedAfter);
                    accumulator.push(orderTableSort);
                    return accumulator;
                }, []);
                request.setOrderId(orderId);
                request.setNext(next);
                request.setStatus(status);
                request.setSortsList(orderSortList);
                request.setSearch(search);
                request.setLocale(locale);
                request.setIsUnread(isUnread);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "getNextCustomerOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var revisionOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RevisionOrderRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "revisionOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var cancelOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "cancelOrdersCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var detectLanguageRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, textPart, metadata;
    var text = _b.text, fileUuid = _b.fileUuid, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DetectLanguageRequest();
                textPart = text && limit(text, 256);
                request.setText(textPart);
                request.setFileUuid(fileUuid);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "detectLanguage",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateArchiveRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, archived = _b.archived, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateArchiveRequest();
                request.setOrderIdsList(orderIds);
                request.setArchived(archived);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "updateArchive",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var ordersViewedRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceCustomerClient,
                        methodName: "ordersViewed",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
