import {
  requestCreateTranslator,
  successCreateTranslator,
  requestGetMe,
  successGetMe,
  requestGetApiKeys,
  successGetApiKeys,
  requestGenerateApiKey,
  successGenerateApiKey,
  requestUpdateApiKeyName,
  successUpdateApiKeyName,
  requestDeleteApiKey,
  successDeleteApiKey,
  requestDeleteMe,
  successDeleteMe,
  requestGetUser,
  successGetUser,
  requestUpdatePassword,
  successUpdatePassword,
  requestRemoveRecentPair,
  successRemoveRecentPair,
  requestDeleteUser,
  successDeleteUser,
  requestGetSubscribeStatus,
  successGetSubscribeStatus,
  requestSetSubscribeStatus,
  successSetSubscribeStatus,
  requestListUsers,
  successListUsers,
  requestListTranslators,
  successListTranslators,
  requestGetLanguagePairStatistic,
  successGetLanguagePairStatistic,
  requestGetSendersEmails,
  successGetSendersEmails,
  requestUpdateUser,
  successUpdateUser,
  requestUpdatePaymentMethod,
  successUpdatePaymentMethod,
  requestUpdateEmailNotifications,
  successUpdateEmailNotifications,
  requestSetSpellCheckerStatus,
  successSetSpellCheckerStatus,
  requestUpdatePersonalSettings,
  successUpdatePersonalSettings,
  requestUpdateBillTo,
  successUpdateBillTo,
  requestAcceptTranslatorPopup,
  successAcceptTranslatorPopup,
  requestSaveCustomerReview,
  successSaveCustomerReview,
  requestSaveVacationPeriodTranslator,
  successSaveVacationPeriodTranslator,
  requestSaveVacationPeriodAdmin,
  successSaveVacationPeriodAdmin,
  requestListLiteUsers,
  successListLiteUsers,
  requestSetCustomerCategory,
  successSetCustomerCategory,
  requestRemoveRoleUser,
  successRemoveRoleUser,
} from "./userActionCreators";
import {
  createTranslatorRequest,
  getMeRequest,
  getApiKeysRequest,
  generateApiKeyRequest,
  updateApiKeyNameRequest,
  deleteApiKeyRequest,
  deleteMeRequest,
  getUserRequest,
  updatePasswordRequest,
  removeRecentPairRequest,
  deleteUserRequest,
  getSubscribeStatusRequest,
  setSubscribeStatusRequest,
  listUsersRequest,
  listTranslatorsRequest,
  getLanguagePairStatisticRequest,
  getSendersEmailsRequest,
  updateUserRequest,
  updatePaymentMethodRequest,
  updateEmailNotificationsRequest,
  setSpellCheckerStatusRequest,
  updatePersonalSettingsRequest,
  updateBillToRequest,
  acceptTranslatorPopupRequest,
  saveCustomerReviewRequest,
  saveVacationPeriodTranslatorRequest,
  saveVacationPeriodAdminRequest,
  listLiteUsersRequest,
  setCustomerCategoryRequest,
  removeRoleUserRequest,
  resolveAI,
} from "../../../services/UserService/requests";
import { requestGetWorkspace, successGetWorkspace } from "../workspace/workspaceActionCreators";
import { getWorkspaceRequest } from "../../../services/WorkspaceServices/requests";
import { showNotification } from "../../Notification";
import { I18n } from "react-redux-i18n";

import { requestMe, succeedMe } from "../../../actions/User";
import { localStorageService } from "../../../services/LocalStorageService";

export const getMeAction = tokenTmp => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();
    const tokenTemp = tokenTmp ? tokenTmp : token;

    dispatch(requestGetMe());
    const data = await getMeRequest({ token: tokenTemp });
    dispatch(successGetMe(data));
    const workspaceLink = window.location.pathname.split("/")[1];
    const isWorkspaceActive = data.workspaceInfoList.find(elem => elem.link === workspaceLink);

    if (!!isWorkspaceActive) {
      dispatch(requestGetWorkspace());
      const workspaceData = await getWorkspaceRequest({ link: workspaceLink, token: tokenTemp });
      localStorageService.setWorkspaceId(workspaceData.id);
      dispatch(successGetWorkspace(workspaceData));
    } else {
      localStorageService.setWorkspaceId("");
    }
    return data;
  };
};

export const resolveAIAction = () => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    const data = await resolveAI({ token });

    return data;
  };
};

export const fetchMeAction = tokenTmp => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    const tokenTemp = tokenTmp ? tokenTmp : token;

    dispatch(requestMe());
    const data = await getMeRequest({ token: tokenTemp });
    dispatch(succeedMe(data));
    return data;
  };
};

export const createTranslatorAction = ({ fullname, name, email, password, paymentMethod, paymentDetails }) => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestCreateTranslator());

    const data = await createTranslatorRequest({
      token,
      fullname,
      name,
      email,
      password,
      paymentMethod,
      paymentDetails,
    });

    dispatch(successCreateTranslator(data));
    return data;
  };
};

export const getApiKeysAction = () => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetApiKeys());
    const data = await getApiKeysRequest({ token });

    dispatch(successGetApiKeys(data));
    return data;
  };
};

export const generateApiKeyAction = (apiName = "") => {
  return async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGenerateApiKey());
    const data = await generateApiKeyRequest({ token, apiName });

    dispatch(successGenerateApiKey(data));
    return data;
  };
};

export const updateApiKeyNameAction =
  ({ oldName, newName }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateApiKeyName());

    const data = await updateApiKeyNameRequest({ oldName, newName, token });
    dispatch(successUpdateApiKeyName(data));
    return data;
  };

export const deleteApiKeyAction =
  ({ apiNamesList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteApiKey());

    const data = await deleteApiKeyRequest({ apiNamesList, token });
    dispatch(successDeleteApiKey(data));
    return data;
  };

export const deleteMeAction =
  ({ reasonsList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteMe());

    const data = await deleteMeRequest({ reasonsList, token });
    dispatch(successDeleteMe(data));
    return data;
  };

export const getUserAction =
  ({ id, role }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetUser());

    const data = await getUserRequest({ id, role, token });
    dispatch(successGetUser(data));
    return data;
  };

export const updatePasswordAction =
  ({ oldPassword, newPassword }, withSuccessMessage) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdatePassword());

    const data = await updatePasswordRequest({ oldpassword: oldPassword, newpassword: newPassword, token });
    dispatch(successUpdatePassword(data));

    withSuccessMessage && dispatch(showNotification(I18n.t("ADMIN_APP.SETTINGS_PAGE.SUCCESS_MESSAGE")));
    return data;
  };

export const removeRecentPairAction =
  ({ pairList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
      me: { me },
    } = getState();

    dispatch(requestRemoveRecentPair());

    const data = await removeRecentPairRequest({ pairList, token });
    dispatch(successRemoveRecentPair(data));

    if (data?.recentPairsList && me?.data) {
      const mePayload = {
        ...me.data,
        recentPairsList: data.recentPairsList,
      };

      dispatch(successGetMe(mePayload));
    }

    return data;
  };

export const setCustomerCategoryAction =
  ({ category }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestSetCustomerCategory());

    const data = await setCustomerCategoryRequest({ category, token });
    dispatch(successSetCustomerCategory(data));
    return data;
  };

export const deleteUserAction =
  ({ useridsList }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestDeleteUser());

    const data = await deleteUserRequest({ useridsList, token });
    dispatch(successDeleteUser(data));
    return data;
  };

export const removeRoleUserAction =
  ({ userId, role }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestRemoveRoleUser());

    const data = await removeRoleUserRequest({ userId, role, token });
    dispatch(successRemoveRoleUser(data));
    return data;
  };

export const getSubscribeStatusAction = () => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestGetSubscribeStatus());

  const data = await getSubscribeStatusRequest({ token });
  dispatch(successGetSubscribeStatus(data));
  return data;
};

export const setSubscribeStatusAction =
  ({ subscribed }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestSetSubscribeStatus());

    const data = await setSubscribeStatusRequest({ subscribed, token });
    dispatch(successSetSubscribeStatus(data));
    return data;
  };

export const listUsersAction =
  ({ offset, limit, search, role, sortField, sortOrder, filters }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    const roleUpperCased = role.toUpperCase();

    dispatch(requestListUsers(roleUpperCased));

    const data = await listUsersRequest({
      limit,
      offset,
      search,
      role: role === "users" ? "UNKNOWN_ROLE" : roleUpperCased,
      sortField,
      sortOrder,
      filters,
      token,
    });
    dispatch(successListUsers(data, roleUpperCased));
    return data;
  };

export const listTranslatorsAction =
  ({ sourceLanguage, targetLanguage }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListTranslators());

    const data = await listTranslatorsRequest({ source: sourceLanguage, target: targetLanguage, token });
    dispatch(successListTranslators(data));
    return data;
  };

export const getLanguagePairStatisticAction =
  ({ userId }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestGetLanguagePairStatistic());

    const data = await getLanguagePairStatisticRequest({ userId, token });
    dispatch(successGetLanguagePairStatistic(data));
    return data;
  };

export const getSendersEmailsAction = () => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestGetSendersEmails());

  const data = await getSendersEmailsRequest({ token });
  dispatch(successGetSendersEmails(data));
  return data;
};

export const updateUserAction =
  (
    {
      fullName,
      name,
      id,
      email,
      billToDetails,
      notes,
      active,
      balanceOverdraftAllowed,
      paused,
      subscribed,
      timeZone,
      sender,
      partnerId,
      startPartnerPeriod,
      endPartnerPeriod,
      rolesList,
      paymentDetails,
      paymentMethod,
      aiAllowed,
    },
    withSuccessMessage = false
  ) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateUser());

    const data = await updateUserRequest({
      token,
      fullName,
      name,
      id,
      email,
      billToDetails,
      notes,
      active,
      balanceOverdraftAllowed,
      paused,
      subscribed,
      timeZone,
      sender,
      partnerId,
      startPartnerPeriod,
      endPartnerPeriod,
      rolesList,
      paymentDetails,
      paymentMethod,
      aiAllowed,
    });

    withSuccessMessage && dispatch(showNotification(I18n.t("ADMIN_APP.USERS_PAGE.EDIT_USER_SECTION.SUCCESS_MESSAGE")));
    dispatch(successUpdateUser(data));
    return data;
  };

export const updatePaymentMethodAction =
  ({ paymentMethod, paymentDetails }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdatePaymentMethod());

    const data = await updatePaymentMethodRequest({ paymentMethod, paymentDetails, token });
    dispatch(successUpdatePaymentMethod(data));
    return data;
  };

export const updateEmailNotificationsAction =
  ({
    cancelledCustomer,
    completedCustomer,
    editedCustomer,
    revisedCustomer,
    completedTranslator,
    editedTranslator,
    revisedTranslator,
    nightTranslator,
  }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateEmailNotifications());

    const data = await updateEmailNotificationsRequest({
      cancelledCustomer,
      completedCustomer,
      editedCustomer,
      revisedCustomer,
      completedTranslator,
      editedTranslator,
      revisedTranslator,
      nightTranslator,
      token,
    });
    dispatch(successUpdateEmailNotifications(data));
    return data;
  };

export const setSpellCheckerStatusAction = spellChecker => async (dispatch, getState) => {
  const {
    app: { token },
  } = getState();

  dispatch(requestSetSpellCheckerStatus());

  const data = await setSpellCheckerStatusRequest({ spellChecker, token });
  dispatch(successSetSpellCheckerStatus(data));
  return data;
};

export const updatePersonalSettingsAction =
  ({ fullName, name, locale, currency, timeZone }, withSuccessMessage = false) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    const currencyList = {
      USD: 0,
      YEN: 1,
    };
    const formattedCurrency = typeof currency === "string" ? currencyList[currency] : currency;

    dispatch(requestUpdatePersonalSettings());

    const data = await updatePersonalSettingsRequest({
      fullName,
      name,
      locale,
      currency: formattedCurrency,
      timeZone,
      token,
    });

    withSuccessMessage && dispatch(showNotification(I18n.t("ADMIN_APP.SETTINGS_PAGE.SUCCESS_MESSAGE")));
    dispatch(successUpdatePersonalSettings(data));
    return data;
  };

export const updateBillToAction =
  ({ billto }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestUpdateBillTo());

    const data = await updateBillToRequest({ billto, token });
    dispatch(successUpdateBillTo(data));
    return data;
  };

export const acceptTranslatorPopupAction =
  ({ fullName, name, email, paymentMethod, paymentDetails, timeZone }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestAcceptTranslatorPopup());

    const data = await acceptTranslatorPopupRequest({
      token,
      fullName,
      name,
      email,
      paymentMethod,
      paymentDetails,
      timeZone,
    });
    dispatch(successAcceptTranslatorPopup(data));
    return data;
  };

export const saveCustomerReviewAction =
  ({ mark, text }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestSaveCustomerReview());

    const data = await saveCustomerReviewRequest({ mark, text, token });
    dispatch(successSaveCustomerReview(data));
    return data;
  };

export const saveVacationPeriodTranslatorAction =
  ({ startVacationPeriod, endVacationPeriod }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestSaveVacationPeriodTranslator());

    const data = await saveVacationPeriodTranslatorRequest({ startVacationPeriod, endVacationPeriod, token });
    dispatch(successSaveVacationPeriodTranslator(data));
    return data;
  };

export const saveVacationPeriodAdminAction =
  ({ userId, dates }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    const { startVacationPeriod, endVacationPeriod } = dates;
    dispatch(requestSaveVacationPeriodAdmin());

    const data = await saveVacationPeriodAdminRequest({ userId, startVacationPeriod, endVacationPeriod, token });
    dispatch(successSaveVacationPeriodAdmin(data));
    return data;
  };

export const listLiteUsersAction =
  ({ search }) =>
  async (dispatch, getState) => {
    const {
      app: { token },
    } = getState();

    dispatch(requestListLiteUsers());

    const data = await listLiteUsersRequest({ search, token });
    dispatch(successListLiteUsers(data));
    return data;
  };
