import React from "react";
import classNames from "classnames";

//TODO рефакторинг
export default function NitroContainer(props) {
  let styles = classNames({
    "nitro-container": true,
    "nitro-container--toolbar": props.toolbar,
    "nitro-container--message": props.message,
    "container-fixed-width": !props.fullWidth,
  });
  styles = classNames(styles, props.className);
  return <div className={styles}>{props.children}</div>;
}
