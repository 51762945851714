import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./css/style.module.css";
import classNames from "classnames";
import IcomoonReact from "icomoon-react";
import iconSet from "../../images/selection.json";
var Button = function (_a) {
    var label = _a.label, onClick = _a.onClick, type = _a.type, name = _a.name, disabled = _a.disabled, buttonSize = _a.buttonSize, _b = _a.buttonColor, buttonColor = _b === void 0 ? "default" : _b, _c = _a.buttonScheme, buttonScheme = _c === void 0 ? "default" : _c, iconName = _a.iconName;
    return (_jsxs("button", { className: classNames(style.button, style[buttonSize], style[buttonScheme], style[buttonColor]), type: type, name: name, disabled: disabled, onClick: onClick, children: [label, iconName && _jsx(IcomoonReact, { iconSet: iconSet, icon: iconName })] }));
};
export default Button;
