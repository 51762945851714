import questionIcon from "../../../../images/svg/question.svg";
import iIcon from "../../../../images/svg/i.svg";
export var iconStyle = {
    question: questionIcon,
    info: iIcon,
};
var contentTheme = {
    orange: "",
    gray: "",
    grayWide: "",
};
var iconColor = {
    iconBlack: "",
    iconGray: "",
    iconRed: "",
    iconOrange: "",
};
