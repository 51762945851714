import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import CustomerHeader from "../components/headers/CustomerHeader";
import SideMenu from "../components/refactored/SideMenu";
import AppLoading from "../components/loaders/AppLoading";
import Footer from "../components/refactored/Footer";
import { toggleSideMenu } from "../actions/AuthorizedActions";
import { setToken, setRefreshToken } from "../actions/App";
import { hideNotifications } from "../actions/AuthorizedThunks";
import { showError, showNewSourceVersion, showWarning } from "../thunks/Notification";
import { getPaymentMethods, setStripePromise } from "../thunks/User.ts";
import { getCookie, setCookie } from "../helpers/common";
import Snackbar from "../components/notification/Snackbar";
import { APP_DISABLED } from "../constants/App";
import "../css/deprecated/buttons.css";
import "../css/deprecated/common.css";
import "../css/deprecated/typography.css";
import "../css/deprecated/forms.css";
import "../css/deprecated/app-wait.css";
import "../css/deprecated/light-box-image.css";
import "../css/deprecated/popover.css";
// eslint-disable-next-line no-unused-vars
import adminApp from "../css/admin-app.css";
import { withWebsocket } from "../components/refactored/Websocket/Websocket";
import { showWarningErrorNotificationWithClose, stopShowNotificationById } from "../actions/NotificationActions";
import { I18n, setLocale } from "react-redux-i18n";
import { changeBalanceTableState, scrollToAddFunds } from "../actions/customer/Balance";
import * as _ from "lodash";
import { bootChat, trackEventChat } from "../helpers/intercomChat";
import { scrollToSettingsBlock } from "../actions/customer/Settings";
import * as Sentry from "@sentry/browser";
import ClearCache from "../components/ClearCache";
import TitlePage from "../components/refactored/TitlePage";
import { changeTableState } from "../actions/customer/NewOrder";
import { getCustomerDiscountInfoAction } from "../thunks/newThunks/discount/discountAsyncActions";
import {
  getOrderCustomerAction,
  getUnreadStatusAction,
  commonListOrdersCustomerAction,
} from "../thunks/newThunks/order/orderAsyncActions";
import eventHandlersStore from "../websocket/eventHandlersStore";
import { ARCHIVED, DONE, INCOMPLETE, QUEUE } from "../constants/Orders";
import moment from "moment";
import { getDiscountFromUrl, getIsAIFromUrl } from "../helpers/getCouponFromUrl";
import { getOrderFileAction } from "../thunks/newThunks/order/orderAsyncActions";
import { useLocation, useNavigate, useParams, Outlet } from "react-router-dom";
import ReviewModal from "../components/refactored/ReviewModal";
import { UserRole } from "@alconost/nitro-services-api/src/proto/user_service_pb";
import {
  updatePersonalSettingsAction,
  getMeAction,
  setCustomerCategoryAction,
} from "../thunks/newThunks/user/userAsyncActions";
import { withAuthProvider } from "../components/refactored/AuthProvider";
import { getInviteInfoAction, getWorkspaceAction } from "../thunks/newThunks/workspace/workspaceAsyncActions";
import { cursorLocalWait } from "../settings/cursorWait";
import { localStorageService } from "../services/LocalStorageService";
import Loading from "../components/Loading";
import { CUSTOMER_CATEGORY_COMPANY, customerCategoryName } from "../helpers/formatCustomerCategory";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

let isClosed = false;

class CustomerApp extends Component {
  warningNotificationId = 0;

  static propTypes = {
    app: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    balance: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    setToken: PropTypes.func.isRequired,
    setRefreshToken: PropTypes.func.isRequired,
  };

  state = {
    isAppLoaded: false,
    isFirstLoading: true,
  };

  setFirstLoading = () => {
    this.setState({ isFirstLoading: false });
  };

  async componentDidMount() {
    const {
      setToken,
      token,
      getMe,
      setLocale,
      refreshToken,
      setRefreshToken,
      changeTableState,
      getUnreadStatusOrder,
      getPaymentMethods,
      setStripe,
      router,
      getWorkspace,
    } = this.props;
    setToken(token);

    setRefreshToken(refreshToken);
    const newOrderLocalStorage = localStorage.getItem("new_order");
    let newOrderDraft = {};

    if (newOrderLocalStorage) {
      newOrderDraft = JSON.parse(newOrderLocalStorage);
      const createdDate = moment(newOrderDraft.createdDate);

      if (moment().diff(createdDate, "minutes") < 1440) {
        changeTableState({ ...newOrderDraft, id: "" });
      }

      localStorage.setItem("new_order", "");
      setCookie("isNewOrderDraft", "");
    }

    const discountCookie = getDiscountFromUrl() ? getDiscountFromUrl() : getCookie("discount");

    if (discountCookie && _.isEmpty(newOrderDraft.discount)) {
      changeTableState({
        discount: {
          code: discountCookie,
        },
      });

      setCookie("discount", "");
    }

    const hasAI = getIsAIFromUrl();
    !!hasAI && window.localStorage.setItem("ai", "true");

    const searchParams = new URLSearchParams(window.location.search);
    const workspaceInviteLink = searchParams.get("invite");

    getMe()
      .then(async userInfo => {
        setLocale(userInfo.locale);
        setCookie("nitro_lang", userInfo.locale, 3650);

        Sentry.configureScope(scope => {
          scope.setUser({
            id: userInfo.id,
          });
        });
        this.activateStatistics(userInfo);

        getPaymentMethods();
        setStripe();
        eventHandlersStore.register("firstOrderCompleted", () => this.checkFirstOrder(userInfo));

        if (workspaceInviteLink) {
          await this.getWorkspaceByInviteLink(workspaceInviteLink);
        }
      })
      .finally(() => {
        this.setState({ isAppLoaded: true });
      });
    getUnreadStatusOrder(UserRole.CUSTOMER);
    eventHandlersStore.register("ordersUpdated", this.ordersUpdatedEvent);
    eventHandlersStore.register("orderViewed", this.updateOrdersCounterEvent);
    eventHandlersStore.register("roleUpdated", this.updatedRoleEvent);
  }

  async componentDidUpdate(prevProps, prevState) {
    const wsMessage = this.props?.ws?.message;
    const isWSMessageChanged = wsMessage !== prevProps?.ws?.message;
    const { getPaymentMethods, setStripe, getUnreadStatusOrder } = this.props;
    if (isWSMessageChanged) {
      eventHandlersStore.get(wsMessage.event)?.(wsMessage);
    }
    if (prevProps.activeWorkspace !== this.props.activeWorkspace) {
      getPaymentMethods();
      setStripe();
      getUnreadStatusOrder(UserRole.CUSTOMER);
    }
  }

  getWorkspaceByInviteLink = async workspaceInviteLink => {
    const { getWorkspace, getInviteInfo, router, showWarningErrorNotificationWithClose, setCustomerCategory } =
      this.props;
    if (!workspaceInviteLink) return;

    try {
      cursorLocalWait(true);
      const inviteData = await getInviteInfo(workspaceInviteLink);
      const { workspaceId } = inviteData;
      await setCustomerCategory({ category: CUSTOMER_CATEGORY_COMPANY });
      const workspaceData = await getWorkspace(workspaceId);
      const { link } = workspaceData;
      cursorLocalWait(false);
      router.navigate(`/${link}/new`);
      window.location.reload();
    } catch (e) {
      cursorLocalWait(false);
      showWarningErrorNotificationWithClose(I18n.t("CUSTOMER_APP.WORKSPACE.ERROR_LINK"));
      router.navigate("/customer/new");
    }
  };

  checkFirstOrder = userInfo => {
    let metadata = {
      user_id: userInfo.id,
      name: userInfo.fullName,
      email: userInfo.email,
    };
    trackEventChat("FIRSTORDER", metadata);
  };
  ordersUpdatedEvent = data => {
    const { getUnreadStatusOrder, params, getOrderCustomer, getOrderFile } = this.props;

    if (data.userAccount === "CUSTOMER") {
      getUnreadStatusOrder(UserRole.CUSTOMER);

      if (window.location.pathname === "/customer/orders") {
        this.commonGetOrders(QUEUE);
        this.commonGetOrders(INCOMPLETE);
        this.commonGetOrders(DONE);
        this.commonGetOrders(ARCHIVED);
      }

      if (params?.id && window.location.pathname.indexOf("/customer/orders") >= 0) {
        data.orderIds.find(element => {
          element.toString() === params.id &&
            getOrderCustomer({ orderId: params.id, needTags: false }).then(responce => {
              getOrderFile(params.id, false, responce.targetLanguage);
            });
        });
      }
    }
  };

  updateOrdersCounterEvent = data => {
    const { getUnreadStatusOrder } = this.props;
    if (data.userAccount === "CUSTOMER") {
      getUnreadStatusOrder(UserRole.CUSTOMER);
    }
  };

  updatedRoleEvent = data => {
    const actualRoles = data?.actualRoles || [];
    const isHasCustomerRole = actualRoles.includes("CUSTOMER");
    const shouldRedirectToRole = actualRoles.length > 0 && !isHasCustomerRole;
    const shouldLogout = !actualRoles.length;

    if (actualRoles.length === 1 && !isHasCustomerRole) {
      if (actualRoles.includes("TRANSLATOR")) {
        window.location.href = "/translator";
      } else if (actualRoles.includes("ADMIN")) {
        window.location.href = "/admin";
      }
      return;
    }

    if (shouldRedirectToRole) {
      window.location.href = "/select-role";
      return;
    }
    if (shouldLogout) this.props.auth.logout();
  };

  commonGetOrders = status => {
    const { commonListOrdersCustomer, tablesState } = this.props;

    commonListOrdersCustomer({
      offset: 0,
      limit: tablesState[status].loadOrdersLimit,
      search: tablesState[status].search,
      status,
      sortField: tablesState[status].sortOrders.id,
      sortOrder: tablesState[status].sortOrders.type,
    });
  };

  componentWillUnmount() {
    eventHandlersStore.unregister("ordersUpdated");
    eventHandlersStore.unregister("orderViewed");
    eventHandlersStore.unregister("firstOrderCompleted");
    eventHandlersStore.unregister("roleUpdated");
    localStorageService.setWorkspaceId("");
  }

  activateStatistics = userInfo => {
    woopra
      .identify({
        id: userInfo.id,
        email: userInfo.email,
        name: userInfo.fullName,
        locale: userInfo.locale,
        is_customer: "true",
        is_translator: "false",
        is_admin: "false",
      })
      .push();

    woopra.track("Launched app", {
      role: "Customer",
      locale: userInfo.locale,
      env: process.env.SERVER_NAME,
    });

    const translator = _.includes(userInfo.rolesList, 1);
    const customer = _.includes(userInfo.rolesList, 2);
    const admin = _.includes(userInfo.rolesList, 3);

    bootChat({
      app_id: "x4sl7uku",
      user_id: userInfo.id,
      name: userInfo.fullName,
      email: userInfo.email,
      language_override: this.props.i18n.locale,
      translator,
      customer,
      admin,
    });

    let metadata = {
      user_id: userInfo.id,
      name: userInfo.fullName,
      email: userInfo.email,
    };
    trackEventChat("Start customer app", metadata);
  };

  showWarning = () => {
    const { showWarning } = this.props;
    setTimeout(function () {
      if (isClosed) {
        showWarning(I18n.t("WARNINGS.RECONNECT"));
      }
    }, 5000);
  };

  stopShowWarningNotification = () => {
    this.props.notification.notificationType === "WARNING" &&
      this.props.stopShowNotificationById(this.props.notification.id);
  };

  handleSwitchLocale = locale => {
    const { me, updatePersonal } = this.props;
    if (!me?.data) return;

    const { fullName, name, currency, timeZone } = me.data;
    const userData = { fullName, name, currency, timeZone, locale };
    updatePersonal(userData).then(() => {
      window.location.reload();
    });
  };

  render() {
    const { me, router, app, notification, showNewSourceVersion, unreadOrdersStatus, activeWorkspace } = this.props;
    const { balance, reserved } = me && me.data;
    const newOrderPath = router.pathname === "/customer/new2";
    const isNotOrderPath = router.location.pathname !== "/customer/new";

    // todo временное решение для белого фона на странице notFound
    const isErrorPage = I18n._translate("ERROR_PAGE.PAGE_TITLE") === app.title;

    const backgroundColor = {
      background: newOrderPath || isErrorPage ? "#FFF" : "#EEE",
    };
    const footerIsNeeded = !newOrderPath;
    const contentBalanceBadge = {
      balance: activeWorkspace ? activeWorkspace.balance : balance,
      reserved: activeWorkspace ? activeWorkspace.reserved : reserved,
    };
    const menuItems = [
      {
        route: "new",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.NEW_ORDER",
      },
      {
        route: "orders",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.ORDERS",
        unreadStatus: unreadOrdersStatus,
      },
      {
        route: "balance",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.BALANCE",
        balance: contentBalanceBadge,
        setAnchor: this.props.changeBalanceTableState,
        scrollToAddFunds: this.props.scrollToAddFunds,
      },
      {
        route: "glossaries",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.GLOSSARY",
      },
      {
        route: "tm",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.TRANSLATION_MEMORY",
      },
      {
        route: "settings",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.API",
        setAnchor: "api",
        scrollTo: this.props.scrollToSettingsBlock,
      },
      {
        route: "support",
        content: "CUSTOMER_APP.SIDE_MENU.TRANSLATE.QUESTION",
      },
    ];

    return (
      <div styleName={"adminApp.container"}>
        {!this.state.isAppLoaded ? (
          <div className={adminApp.loadingContainer}>
            <Loading className={adminApp.loading} display={true} />
          </div>
        ) : (
          <>
            <TitlePage title={app.title} />
            <div id="globalWrapper" styleName={"adminApp.globalWrapper"} style={backgroundColor}>
              <div styleName={"adminApp.content"}>
                <AppLoading display={app.status === APP_DISABLED} />
                <CustomerHeader
                  desktopStatus={this.props.desktop.status}
                  menuToggler={this.props.toggleSideMenu}
                  messageCount={unreadOrdersStatus}
                  messageCountIsFirst={true}
                  user={me.data}
                  itemMenuNewOrder={true}
                />
                <SideMenu
                  router={this.props.router}
                  toggleSideMenu={this.props.toggleSideMenu}
                  menuItems={menuItems}
                  display={app.showSideMenu}
                />

                {this.props.children}
                <Outlet />
              </div>
              {!activeWorkspace && isNotOrderPath && <ReviewModal />}
              {footerIsNeeded && (
                <Footer
                  locale={this.props.i18n.locale}
                  german={true}
                  japanese={true}
                  handleSaveLocale={this.handleSwitchLocale}
                />
              )}
            </div>
            <ClearCache
              notification={notification}
              showNewSourceVersion={showNewSourceVersion}
              isFirstLoading={this.state.isFirstLoading}
              setFirstLoading={this.setFirstLoading}
            />
          </>
        )}
        <Snackbar notification={notification} autoHideDuration={3000} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
    desktop: state.desktop,
    order: state.order,
    app: state.app,
    me: state.me.me,
    activeWorkspace: state.me.activeWorkspace,
    balance: state.customerBalance,
    i18n: state.i18n,
    token: getCookie("auth_token") || false,
    refreshToken: getCookie("refresh_token") || false,
    unreadOrdersStatus: state.domain.order.unreadOrdersStatus.count,
    tablesState: state.ordersTemp.tablesState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMe: () => {
      return dispatch(getMeAction());
    },
    getWorkspace: id => {
      return dispatch(getWorkspaceAction({ id }));
    },
    setToken: token => {
      dispatch(setToken(token));
    },
    setRefreshToken: refreshToken => {
      dispatch(setRefreshToken(refreshToken));
    },
    setLocale: locale => {
      dispatch(setLocale(locale));
    },
    toggleSideMenu: isOpened => {
      dispatch(toggleSideMenu(isOpened));
    },
    hideNotifications: () => {
      dispatch(hideNotifications());
    },
    showWarning: message => {
      return dispatch(showWarning(message));
    },
    showWarningErrorNotificationWithClose: message => {
      dispatch(showWarningErrorNotificationWithClose(message));
    },
    stopShowNotificationById: id => {
      dispatch(stopShowNotificationById(id));
    },
    changeBalanceTableState: state => {
      dispatch(changeBalanceTableState(state));
    },
    scrollToAddFunds: itemId => {
      dispatch(scrollToAddFunds(itemId));
    },
    scrollToSettingsBlock: itemId => dispatch(scrollToSettingsBlock(itemId)),
    updatePersonal: userData => dispatch(updatePersonalSettingsAction(userData)),
    showNewSourceVersion: (message, handleRefresh) => dispatch(showNewSourceVersion(message, handleRefresh)),
    changeTableState: state => {
      return dispatch(changeTableState(state));
    },
    getUnreadStatusOrder: role => {
      return dispatch(getUnreadStatusAction({ role }));
    },
    commonListOrdersCustomer: ({ offset, limit, search, status, sortField, sortOrder, cursorWatch }) =>
      dispatch(
        commonListOrdersCustomerAction({
          offset,
          limit,
          search,
          status,
          sortField,
          sortOrder,
          cursorWatch,
        })
      ),
    getOrderCustomer: ({ orderId, needTags }) => {
      return dispatch(getOrderCustomerAction({ orderId, needTags }));
    },
    setDiscountInfo: code => {
      return dispatch(getCustomerDiscountInfoAction({ code }));
    },
    getPaymentMethods: () => {
      return dispatch(getPaymentMethods());
    },

    setStripe: () => {
      return dispatch(setStripePromise());
    },
    getOrderFile: (orderId, isNeedTags, targetLanguage) => {
      return dispatch(getOrderFileAction({ orderId, isNeedTags, targetLanguage }));
    },
    showErrorNotification: message => {
      return dispatch(showError(message));
    },
    getInviteInfo: code => {
      return dispatch(getInviteInfoAction(code));
    },
    setCustomerCategory: ({ category }) => {
      return dispatch(setCustomerCategoryAction({ category: category }));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWebsocket(withAuthProvider(CustomerApp))));
