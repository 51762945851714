// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__iconContainer__Yn1Xls{
    margin: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.style-module__contentWrapper__uPQWHu{
    color: #424242;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    padding: 7px 15px 11px 15px;
    outline: none;
    max-width: 200px;
    border-radius: 1px;
    box-shadow: 0px 2px 10px 0px rgba(33, 33, 33, 0.1);
}

.style-module__contentWrapper__uPQWHu.style-module__gray__nEkWTa{
    border: 1px solid #BDBDBD;
    background-color: #FFFFFF;
}

.style-module__contentWrapper__uPQWHu.style-module__grayWide__hvfdXK{
    max-width: 270px;
    border: 1px solid #BDBDBD;
    background-color: #FFFFFF;
}

.style-module__contentWrapper__uPQWHu.style-module__orange__vJdnj_{
    border: 1px solid #FF9800;
    background-color: #FFF3E0;
}

.style-module__iconBlack__aEId8I{
    color: #212121;
}

.style-module__iconGray__B_ikHt{
    color: #BDBDBD;
}

.style-module__iconWhite__wYkFV4{
    color: #FFFFFF;
}

.style-module__iconRed__qzXztw{
    color: #EF5350;
}

.style-module__iconOrange__tzrra5{
    color: #ffcc80;
}

`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `style-module__iconContainer__Yn1Xls`,
	"contentWrapper": `style-module__contentWrapper__uPQWHu`,
	"gray": `style-module__gray__nEkWTa`,
	"grayWide": `style-module__grayWide__hvfdXK`,
	"orange": `style-module__orange__vJdnj_`,
	"iconBlack": `style-module__iconBlack__aEId8I`,
	"iconGray": `style-module__iconGray__B_ikHt`,
	"iconWhite": `style-module__iconWhite__wYkFV4`,
	"iconRed": `style-module__iconRed__qzXztw`,
	"iconOrange": `style-module__iconOrange__tzrra5`
};
export default ___CSS_LOADER_EXPORT___;
