import { createThunkReducer } from "../helpers/createThunkReducer";
import reduceReducers from "reduce-reducers";
import { OrderTypes } from "../thunks/newThunks/order/orderActionTypes";
import { LEXIQA_STATUSES } from "../components/VirtualizedEditorContainer/components/RightSegment/lexiqa";

const getOrderPayload = payload => {
  const orderHistory = [...payload.comments.messagesList, ...payload.comments.statusesList];
  payload.comments.messagesList = orderHistory.sort((element1, element2) => {
    if (element1.time.seconds < element2.time.seconds) {
      return -1;
    }
    if (element1.time.seconds > element2.time.seconds) {
      return 1;
    }
    return 0;
  });

  return payload;
};

const initialState = {
  ["orderStatus"]: { status: "", data: {} },
  ["nextOrder"]: { status: "", data: {}, orderMessages: [] },
  ["nextPreviousOrder"]: { status: "", data: {}, orderMessages: [] },
  ["order"]: { status: "", data: {}, orderMessages: [] },
  ["positionsTerms"]: { status: "", data: {} },
  ["clientId"]: "",
  ["spellChecker"]: true,
  translationDraft: {},
};

const getOrderStatus = createThunkReducer("orderStatus", "GET_QUEUE_STATUS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: { data: payload } };
  },
});

const continueTranslatorOrderWithDraft = createThunkReducer("nextOrder", "CONTINUE_TRANSLATOR_ORDER_WITH_DRAFT", {
  handleSuccessAction: (state = initialState, payload) => {
    return {
      requestState: {
        ...state.nextOrder,
        data: payload,
        // orderMessages: getOrderPayload(payload).comments.messagesList,
        // terms: payload.termsList,
        // groupInfo: payload.groupInfo,
      },
    };
  },
});
const getNextDesktopOrder = createThunkReducer("nextOrder", "GET_NEXT_DESKTOP_ORDER", {
  handleSuccessAction: (state = initialState, payload) => {
    return {
      requestState: {
        ...state.nextOrder,
        data: payload.order,
        orderMessages: getOrderPayload(payload).comments.messagesList,
        terms: payload.termsList,
        groupInfo: payload.groupInfo,
      },
    };
  },
});
const startAdminEditDraftOrder = createThunkReducer("nextOrder", "START_ADMIN_EDIT_DRAFT_ORDER", {
  handleSuccessAction: (state = initialState, payload) => {
    return {
      requestState: {
        ...state.nextOrder,
        data: payload,
        // orderMessages: getOrderPayload(payload).comments.messagesList,
        // terms: payload.termsList,
        // groupInfo: payload.groupInfo,
      },
    };
  },
});

const getOrderTranslator = createThunkReducer("nextOrder", "GET_ORDER_TRANSLATOR", {
  handleSuccessAction: (state = initialState, payload) => {
    return {
      requestState: {
        ...state.nextOrder,
        data: payload.order,
        orderMessages: getOrderPayload(payload).comments.messagesList,
        terms: payload.termsList,
        groupInfo: payload.groupInfo,
      },
    };
  },
});

const completeOrder = createThunkReducer("nextOrder", "COMPLETE_ORDER", {
  handleSuccessAction: () => {
    return { requestState: { data: {} } };
  },
});

const skipOrder = createThunkReducer("nextOrder", "SKIP_ACCEPTED_ORDER", {
  handleSuccessAction: () => {
    return { requestState: { data: {} } };
  },
});

const getPositionsTerms = createThunkReducer("positionsTerms", "GET_POSITIONS_TERMS", {
  handleSuccessAction: (state, payload) => {
    return { requestState: payload };
  },
});

function initialReducer(state = initialState) {
  return state;
}

export function updateOrder(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_TARGET_TEXT":
      return {
        ...state,
        clientId: action.clientId,
        nextOrder: {
          ...state.nextOrder,
          data: {
            ...state.nextOrder.data,
            targetText: action.targetText,
          },
        },
      };
    case "CHANGE_SPELLCHECKER":
      return {
        ...state,
        spellChecker: action.spellChecker,
      };
    case "UPDATE_ORDER_ESTIMATED_TIME":
      return {
        ...state,
        nextOrder: {
          ...state.nextOrder,
          data: action.order.oder,
          groupInfo: action.order.groupInfo,
        },
      };
    case "SAVE_TRANSLATION_DRAFT":
      return {
        ...state,
        translationDraft: action,
      };
    case "RESET_NEXT_ORDER":
      return {
        ...state,
        nextOrder: { status: "", data: {}, orderMessages: [] },
      };
    case "GET_ORDER_FILE_SUCCESS":
      return {
        ...state,
        nextOrder: {
          ...state.nextOrder,
          sourceFile: action.payload.sourceFileMetadata || "",
          targetFile: action.payload.targetFileMetadata || "",
        },
        order: {
          ...state.order,
          sourceFile: action.payload.sourceFileMetadata || "",
          targetFile: action.payload.targetFileMetadata || "",
        },
      };
    case OrderTypes.GET_LEXIQA_REQUEST:
      return {
        ...state,
        nextOrder: {
          ...state.nextOrder,
          lexiqa: {
            status: LEXIQA_STATUSES.loading,
          },
        },
      };
    case OrderTypes.GET_LEXIQA_SUCCESS:
      return {
        ...state,
        nextOrder: {
          ...state.nextOrder,
          lexiqa: {
            // ...state.nextOrder.lexiqa,
            status: LEXIQA_STATUSES.success,
            data: action.payload,
          },
        },
      };
    case OrderTypes.GET_LEXIQA_FAILED:
      return {
        ...state,
        nextOrder: {
          ...state.nextOrder,
          lexiqa: {
            status: LEXIQA_STATUSES.failed,
            data: null,
          },
        },
      };
    default:
      return state;
  }
}

export const desktop = reduceReducers(
  initialReducer,
  getNextDesktopOrder,
  getOrderStatus,
  updateOrder,
  completeOrder,
  skipOrder,
  getPositionsTerms,
  continueTranslatorOrderWithDraft,
  getOrderTranslator,
  startAdminEditDraftOrder
);

export default desktop;
