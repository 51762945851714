import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, PopoverArrow, PopoverContent, PopoverReference } from "../../../overlay";
import { HandySvg } from "handy-svg";
import style from "./css/style.module.css";
import classNames from "classnames";
import { iconStyle } from "./models";
var Question = function (_a) {
    var _b = _a.placement, placement = _b === void 0 ? "bottom-start" : _b, question = _a.question, _c = _a.iconType, iconType = _c === void 0 ? "question" : _c, _d = _a.contentTheme, contentTheme = _d === void 0 ? "gray" : _d, _e = _a.iconColor, iconColor = _e === void 0 ? "iconGray" : _e, _f = _a.offset, offset = _f === void 0 ? 13 : _f, _g = _a.initialOpen, initialOpen = _g === void 0 ? false : _g;
    return (_jsxs(Popover, { placement: placement, offset: 13, initialOpen: initialOpen, children: [_jsx(PopoverReference, { isCustomReference: true, children: _jsx("span", { className: style.iconContainer, children: _jsx(HandySvg, { src: iconStyle[iconType], width: "15", height: "15", className: style[iconColor] }) }) }), _jsxs(PopoverContent, { style: { zIndex: offset }, className: classNames(style.contentWrapper, style[contentTheme]), children: [_jsx(PopoverArrow, { width: 18, height: 9, stroke: "#BDBDBD", strokeWidth: 1, fill: "white" }), question] })] }));
};
export default Question;
