import React, { Component } from "react";
import Popover from "react-bootstrap/Popover";
// eslint-disable-next-line no-unused-vars
import styles from "./MobileSignBtn.css";
import "../../css/deprecated/popover.css";
import HtmlTranslate from "../HtmlTranslate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { HandySvg } from "handy-svg";
import logoutIcon from "../../images/svg/logout-btn.svg";

class MobileSignBtn extends Component {
  render() {
    const { handlerAuth, handlerSignUp } = this.props;
    const popover = (
      <Popover styleName={"styles.signBtnMobilePopover"}>
        <ul styleName={"styles.signBtnList"}>
          <li>
            <a onClick={handlerAuth} styleName={"styles.signLink"}>
              <HtmlTranslate value={"CUSTOMER_APP.NEW_ORDER_PAGE.SIGNIN_BUTTON"} />
            </a>
          </li>
          <li>
            <a onClick={handlerSignUp} styleName={"styles.signLink"}>
              <HtmlTranslate value={"CUSTOMER_APP.NEW_ORDER_PAGE.SIGNUP_BUTTON"} />
            </a>
          </li>
        </ul>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
        <a styleName={"styles.signBtnMobile"}>
          <span>
            <HandySvg width="16" height="16" src={logoutIcon} />
          </span>
        </a>
      </OverlayTrigger>
    );
  }
}

export default MobileSignBtn;
