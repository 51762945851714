var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { arrow, autoUpdate, offset as offsetMiddleware, shift, useClick, useDismiss, useFloating, useInteractions, useRole, flip, } from "@floating-ui/react";
import { useMemo, useRef, useState } from "react";
export var usePopover = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.initialOpen, initialOpen = _c === void 0 ? false : _c, middlewares = _b.middlewares, modal = _b.modal, offset = _b.offset, setControlledOpen = _b.onOpenChange, controlledOpen = _b.open, _d = _b.placement, placement = _d === void 0 ? "bottom" : _d, _e = _b.shiftPadding, shiftPadding = _e === void 0 ? {} : _e;
    var _f = useState(initialOpen), uncontrolledOpen = _f[0], setUncontrolledOpen = _f[1];
    var _g = useState(), labelId = _g[0], setLabelId = _g[1];
    var _h = useState(), descriptionId = _h[0], setDescriptionId = _h[1];
    var arrowRef = useRef(null);
    var open = controlledOpen !== null && controlledOpen !== void 0 ? controlledOpen : uncontrolledOpen;
    var setOpen = setControlledOpen !== null && setControlledOpen !== void 0 ? setControlledOpen : setUncontrolledOpen;
    var data = useFloating({
        middleware: __spreadArray(__spreadArray([
            offsetMiddleware(offset),
            shift({ padding: shiftPadding })
        ], (middlewares !== null && middlewares !== void 0 ? middlewares : []), true), [
            flip(),
            arrowRef && arrow({ element: arrowRef, padding: 8 }),
        ], false),
        onOpenChange: setOpen,
        open: open,
        placement: placement,
        whileElementsMounted: autoUpdate,
    });
    var context = data.context;
    var click = useClick(context, {
        enabled: controlledOpen == null,
    });
    var dismiss = useDismiss(context);
    var role = useRole(context);
    var interactions = useInteractions([click, dismiss, role]);
    return useMemo(function () { return (__assign(__assign(__assign({ open: open, setOpen: setOpen, arrowRef: arrowRef }, interactions), data), { descriptionId: descriptionId, labelId: labelId, modal: modal, setDescriptionId: setDescriptionId, setLabelId: setLabelId })); }, [open, setOpen, interactions, data, modal, labelId, descriptionId]);
};
