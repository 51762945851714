// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-module__button__wBVo6l {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px; 
    box-sizing: border-box;
    border-radius: 3px;
    background-color: inherit;
}
.style-module__button__wBVo6l svg{
    width: 16px;
}

.style-module__button__wBVo6l:disabled{
    opacity: 0.5;
}
.style-module__medium__Aha7pY{
   padding: 6px 20px;
}
.style-module__big__o9eDh3{
    width: 288px;
    padding: 10px 0;
    font-size: 16px;
}
.style-module__small__uImnrc{
    padding: 4px 12px;
    line-height: 16px;
}
.style-module__link__iHmb_1{
    line-height: 20px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    border: none;
    color: #9E9E9E;
}
.style-module__link__iHmb_1:hover{
    color: #424242;
}
.style-module__link__iHmb_1:active{
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #424242;
}
/*secondary*/
.style-module__secondary__pcQU4T{
    border: 1px solid;
}
.style-module__secondary__pcQU4T.style-module__green__lOkbdL svg path{
    color: #43A047;
    fill: #43A047;
}
.style-module__secondary__pcQU4T.style-module__green__lOkbdL:hover svg path{
    color: #FFFFFF;
    fill: #FFFFFF; 
}
.style-module__secondary__pcQU4T.style-module__big__o9eDh3{
    padding: 9px 0;
}
.style-module__secondary__pcQU4T.style-module__medium__Aha7pY{
    padding: 5px 20px;
}
.style-module__secondary__pcQU4T.style-module__small__uImnrc{
    padding: 3px 12px;
}
.style-module__secondary__pcQU4T.style-module__red__icaqbC{
    border-color: #EF5350;
    color:  #EF5350;
}
.style-module__secondary__pcQU4T.style-module__red__icaqbC:hover{
    background-color: #EF5350;
    color: #FFFFFF;
}
.style-module__secondary__pcQU4T.style-module__green__lOkbdL{
    border-color: #43A047;
    color:  #43A047;
}
.style-module__secondary__pcQU4T.style-module__green__lOkbdL:hover{
    background-color: #43A047;
    color: #FFFFFF;
}
.style-module__secondary__pcQU4T.style-module__blue___VJGB4{
    border-color: #1E88E5;
    color:  #1E88E5;
}
.style-module__secondary__pcQU4T.style-module__blue___VJGB4:hover{
    background-color: #1E88E5;
    color: #FFFFFF;
}
.style-module__secondary__pcQU4T.style-module__yellow__qJ2VG7{
    border-color: #FF9800;
    color:  #FF9800;
}
.style-module__secondary__pcQU4T.style-module__yellow__qJ2VG7:hover{
    background-color: #FF9800;
    color: #FFFFFF;
}
.style-module__secondary__pcQU4T.style-module__white__d1JVcy{
    border-color: #FFFFFF;
    color:  #FFFFFF;
}
/*primary*/
.style-module__primary__trg9Bu.style-module__green__lOkbdL svg path{
    color: #FFFFFF;
    fill: #FFFFFF;
}
.style-module__primary__trg9Bu{
    border: none;
}
.style-module__primary__trg9Bu.style-module__green__lOkbdL{
    background-color: #43A047;
    color: #FFFFFF;
}
.style-module__primary__trg9Bu.style-module__green__lOkbdL:hover{
    background-color: #388E3C;
    color: #FFFFFF;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `style-module__button__wBVo6l`,
	"medium": `style-module__medium__Aha7pY`,
	"big": `style-module__big__o9eDh3`,
	"small": `style-module__small__uImnrc`,
	"link": `style-module__link__iHmb_1`,
	"secondary": `style-module__secondary__pcQU4T`,
	"green": `style-module__green__lOkbdL`,
	"red": `style-module__red__icaqbC`,
	"blue": `style-module__blue___VJGB4`,
	"yellow": `style-module__yellow__qJ2VG7`,
	"white": `style-module__white__d1JVcy`,
	"primary": `style-module__primary__trg9Bu`
};
export default ___CSS_LOADER_EXPORT___;
