import React, { Component } from "react";
import { Provider } from "react-redux";
import Login from "./forms/Login";
import Register from "./forms/Register";
import Forgot from "./forms/Forgot";
import ForgotReset from "./forms/ForgotReset";
import { closePopovers } from "../helpers/common";
import FormsApp from "./FormsApp";
import SignupSuccess from "./forms/SignupSuccess/SignupSuccess";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <FormsApp />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <Register />,
      },
      {
        path: "signup/confirm",
        element: <SignupSuccess />,
      },
      {
        path: "forgot-password",
        element: <Forgot />,
      },
      {
        path: "forgot-password/reset",
        element: <ForgotReset />,
      },
    ],
  },
]);
export default class FormsRoot extends Component {
  componentDidMount() {
    const body = document.getElementsByTagName("body")[0];
    body.onresize = closePopovers;
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    );
  }
}
