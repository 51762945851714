// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message-handler {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: -50px;
  left: 0;
  width: 100%;
  transition: bottom 0.25s;
}
--active.error-message-handler {
    bottom: 0px;
  }
`, "",{"version":3,"sources":["webpack://./src/css/deprecated/error-message-handler.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,OAAO;EACP,WAAW;EACX,wBAAwB;AAI1B;AAHE;IACE,WAAW;EACb","sourcesContent":[".error-message-handler {\r\n  display: flex;\r\n  justify-content: center;\r\n  position: fixed;\r\n  bottom: -50px;\r\n  left: 0;\r\n  width: 100%;\r\n  transition: bottom 0.25s;\r\n  &--active {\r\n    bottom: 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
