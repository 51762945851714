import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import UnauthorizedHeader from "../components/headers/UnauthorizedHeader";
import ErrorMessageHandler from "../components/errors/ErrorMessageHandler";
import AppLoading from "../components/loaders/AppLoading";
import HtmlTranslate from "../components/HtmlTranslate";
import NitroContainer from "../components/layout/NitroContainer";
import { bootChat, trackEventChat } from "../helpers/intercomChat";
import Snackbar from "../components/notification/Snackbar";
import { APP_DISABLED } from "../constants/App";
// eslint-disable-next-line no-unused-vars
import coupon from "../css/coupon.css";
import "../css/deprecated/buttons.css";
import "../css/deprecated/common.css";
import "../css/deprecated/typography.css";
import "../css/deprecated/forms.css";
import "../css/deprecated/checkboxes.css";
import "../css/deprecated/app-wait.css";
import "../css/deprecated/popover.css";

import { I18n } from "react-redux-i18n";
import { showNewSourceVersion, showNotification } from "../thunks/Notification";
import Footer from "../components/refactored/Footer";
import { isLocalStorageSupported, setCookie } from "../helpers/common";
import ClearCache from "../components/ClearCache";
import { getCouponInfoAction } from "../thunks/newThunks/coupons/couponsAsyncActions";
import { formatCouponAsCurrency } from "../helpers/formatCurrency";
import { USD } from "../constants/Currency";
import TitlePage from "../components/refactored/TitlePage";
import nodeUrl from "url";
import { cursorLocalWait } from "../settings/cursorWait";
import { getCouponFromUrl, getDiscountFromUrl, getIsAIFromUrl } from "../helpers/getCouponFromUrl";
import { withRouter } from "./CustomerApp";
import { Outlet } from "react-router-dom";
import { getInviteInfoAction } from "../thunks/newThunks/workspace/workspaceAsyncActions";

class FormsApp extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    socialSignin: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const currentUrl = window.location.href;
    const code = nodeUrl.parse(currentUrl, true, true).query.code;
    //[WIP] get all query params from url (code, getCouponFromUrl, getDiscountFromUrl)
    if (code) {
      setCookie("auth_code", code);
      cursorLocalWait(true);
    }

    this.state = {
      isFirstLoading: true,
      showCouponTicket: false,
      isCodeAuth: !!code,
      invitedWorkspaceEmail: "",
    };
  }

  setFirstLoading = () => {
    this.setState({ isFirstLoading: false });
  };

  async componentDidMount() {
    const { showNotification, getCouponInfo } = this.props;
    const auth = isLocalStorageSupported(localStorage) && localStorage.getItem("auth_token");
    auth === "delete" && showNotification(I18n.t("CUSTOMER_APP.SETTINGS_PAGE.DELETE_ACCOUNT.SUCCESS_MESSAGE"));
    auth === "delete" && isLocalStorageSupported(localStorage) && localStorage.removeItem("auth_token");

    const hasAI = getIsAIFromUrl();
    !!hasAI && window.localStorage.setItem("ai", "true");

    bootChat({
      app_id: "x4sl7uku",
      language_override: this.props.i18n.locale,
    });

    trackEventChat("Start forms app");

    const couponCode = getCouponFromUrl();

    if (couponCode) {
      getCouponInfo(couponCode).then(coupon => {
        if (coupon.amount) {
          this.setState({
            showCouponTicket: true,
          });
        }
      });
    }

    const discount = getDiscountFromUrl();
    if (discount) {
      setCookie("discount", discount);
    }
    const searchParams = new URLSearchParams(window.location.search);
    const workspaceInviteLink = searchParams.get("invite");
    if (workspaceInviteLink) {
      try {
        cursorLocalWait(true);
        const inviteData = await this.props.getInviteInfo(workspaceInviteLink);
        const { email } = inviteData;
        this.setState({ invitedWorkspaceEmail: email });
        cursorLocalWait(false);
      } catch (e) {
        cursorLocalWait(false);
        console.log(e);
      }
    }
  }

  render() {
    if (this.state.isCodeAuth) {
      return <div style={{ margin: 8, fontSize: 14, fontWeight: 400 }}>{I18n.t("LOGIN_PAGE.WAITING_MESSAGE")}</div>;
    }

    const year = new Date().getFullYear();
    const { notification, showNewSourceVersion, coupon } = this.props;
    const { showCouponTicket, invitedWorkspaceEmail } = this.state;
    const couponAmount = formatCouponAsCurrency({
      value: coupon.amount,
      currency: USD,
    });
    const contextData = { invitedWorkspaceEmail };
    return (
      <div className="app">
        <TitlePage title={this.props.app.title}>
          <div className="global-wrapper" style={{ background: "#eee" }}>
            <UnauthorizedHeader />

            <NitroContainer>
              <div className="content-column content-column__wrapper">
                <AppLoading display={this.props.app.status === APP_DISABLED} />
                <h1 className="form-heading">
                  <HtmlTranslate value="APP.HEADING" />
                </h1>
                {showCouponTicket && coupon.status === "valid" && (
                  <div styleName={"coupon.couponContainer"}>
                    <div styleName={"coupon.coupon"}>
                      <div styleName={"coupon.amount"}>{couponAmount}</div>
                      {/* <div styleName={"coupon.line"}></div> */}
                      <div styleName={"coupon.description"}>
                        {I18n.t("LOGIN_PAGE.COUPON_TICKET.DESCRIPTION", {
                          amount: couponAmount,
                        })}
                      </div>
                      <div styleName={"coupon.cornerContainer"}>
                        <div styleName={"coupon.cornerIcon"} />
                      </div>
                    </div>
                  </div>
                )}
                {this.props.children}
                <Outlet context={contextData} />
              </div>
            </NitroContainer>

            <ErrorMessageHandler socialSignin={this.props.socialSignin} />

            <div className="wrapper-helper">
              <div className="footer-block text-gray text-small" style={{ margin: "0 auto", position: "relative" }}>
                ©2004-{year}{" "}
                <a className="footer-gray" href="https://alconost.com">
                  Alconost Inc.
                </a>{" "}
                · 700 N Fairfax St, suite 614-С, Alexandria, Virginia 22314, US · +1&nbsp;(571)&nbsp;357&nbsp;3985 ·{" "}
                <a className="footer-gray" href="mailto:nitro@alconost.com">
                  nitro@alconost.com
                </a>
                &nbsp;·&nbsp;
                <a className="footer-gray" target="_blank" href="https://alconost.com/legal/terms-of-service">
                  <HtmlTranslate value="FOOTER.TERMS_OF_SERVICE_LINK" />
                </a>{" "}
                ·{" "}
                <a className="footer-gray" target="_blank" href="https://alconost.com/legal/privacy-policy">
                  <HtmlTranslate value="FOOTER.PRIVACY_POLICY_LINK" />
                </a>
              </div>
            </div>
            <Footer
              locale={this.props.i18n.locale}
              japanese={true}
              style={{
                margin: 0,
                paddingTop: 50,
                paddingBottom: 50,
                background: "#eee",
              }}
            />
          </div>
        </TitlePage>
        <Snackbar notification={this.props.notification} autoHideDuration={5000} />
        <ClearCache
          notification={notification}
          showNewSourceVersion={showNewSourceVersion}
          isFirstLoading={this.state.isFirstLoading}
          setFirstLoading={this.setFirstLoading}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    i18n: state.i18n,
    coupon: state.coupon.coupon.data,
    notification: state.notification,
    socialSignin: state.socialSignin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: message => dispatch(showNotification(message)),
    showNewSourceVersion: (message, handleRefresh) => dispatch(showNewSourceVersion(message, handleRefresh)),
    getCouponInfo: code => dispatch(getCouponInfoAction({ code: code })),
    getInviteInfo: code => dispatch(getInviteInfoAction(code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormsApp));
