// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNXB4IiB2aWV3Qm94PSIwIDAgMjYgMjUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+RUU2OUUzQjQtMkJENC00MzMxLUFCRTEtQ0NCNjI5OUZENUY2PC90aXRsZT4KICAgIDxnIGlkPSLwn5STLW5pdHJvLWxvZ2luIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ic2lnbi11cC1mYWNlYm9vaytnb29nbGUtZmlyc3QiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03OTQuMDAwMDAwLCAtMjUxLjAwMDAwMCkiIGZpbGw9IiNCQkRFRkIiPgogICAgICAgICAgICA8cGF0aCBkPSJNODIwLDI1MSBMNzk5LDI3NiBMNzk0LjkyNTI1NiwyNTkuOTYwOTcyIEM3OTQuNTE3Mjg5LDI1OC4zNTUxMyA3OTUuNDg4MzU4LDI1Ni43MjI2MTUgNzk3LjA5NDIsMjU2LjMxNDY0OCBDNzk3LjExNDM4NywyNTYuMzA5NTE5IDc5Ny4xMzQ2MjcsMjU2LjMwNDYwMSA3OTcuMTU0OTE3LDI1Ni4yOTk4OTQgTDgyMCwyNTEgTDgyMCwyNTEgWiIgaWQ9ImNvdXBvbi0yIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupon__couponContainer__Ovo4Qr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.coupon__coupon__Gd7TY9 {
  background: linear-gradient(310deg, transparent 16px, #1e88e5 16px);
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  min-height: 74px;
  max-width: 360px;
  display: flex;
}

.coupon__amount__YceDCx {
  transform: rotate(360deg);
  color: #fff040;
  /*font-family: Panton;*/
  font-size: 29px;
  font-weight: 600;
  letter-spacing: -0.36px;
  line-height: 35px;
  text-align: center;
  padding: 21px 15px 18px 12px;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
}

.coupon__description__JAHQLp {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 21px;
  padding: 16px 14px 16px 14px;
  display: flex;
  align-items: center;
}

.coupon__cornerContainer__bDGc5v {
  display: flex;
  align-items: flex-end;
}

.coupon__cornerIcon__gcuDV9 {
  height: 25px;
  width: 25px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    no-repeat;
}

/*@media (max-width: 400px) {*/
/*  .coupon {*/
/*    height: 74px;*/
/*    width: 288px;*/
/*    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjg4cHgiIGhlaWdodD0iNzRweCIgdmlld0JveD0iMjg4IDc0IDAgMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT5FOEIxQTNCMy1EQkQ3LTQzMTAtODEwRS0yRDYyMEJBMzJBNzU8L3RpdGxlPgogICAgPGcgaWQ9IvCflJMtbml0cm8tbG9naW4iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJzaWduLXVwLWZhY2Vib29rK2dvb2dsZS1maXJzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ2MC4wMDAwMDAsIC0yMDIuMDAwMDAwKSIgZmlsbD0iIzFFODhFNSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00NjMsMjAyIEw4MTcsMjAyIEM4MTguNjU2ODU0LDIwMiA4MjAsMjAzLjM0MzE0NiA4MjAsMjA1IEw4MjAsMjUxIEw4MjAsMjUxIEw3OTksMjc2IEw0NjMsMjc2IEM0NjEuMzQzMTQ2LDI3NiA0NjAsMjc0LjY1Njg1NCA0NjAsMjczIEw0NjAsMjA1IEM0NjAsMjAzLjM0MzE0NiA0NjEuMzQzMTQ2LDIwMiA0NjMsMjAyIFoiIGlkPSJjb3Vwb24tMSI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+') no-repeat;*/
/*  }*/

/*  .description {*/
/*    padding: 6px 10px 5px 14px;*/
/*  }*/
/*}*/
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"couponContainer": `coupon__couponContainer__Ovo4Qr`,
	"coupon": `coupon__coupon__Gd7TY9`,
	"amount": `coupon__amount__YceDCx`,
	"description": `coupon__description__JAHQLp`,
	"cornerContainer": `coupon__cornerContainer__bDGc5v`,
	"cornerIcon": `coupon__cornerIcon__gcuDV9`
};
export default ___CSS_LOADER_EXPORT___;
